import React from 'react';
import * as PropTypes from 'prop-types';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';

const ACH: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  return (
    <div className='tandc-content__container terms-condition' id='ach'>
      <div>
        <h2 className='tandc-title-header'>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TITLE)}</h2>

        {brand === 'Audi' ? (
          <>
            <p>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TEXT1_AUDI)}</p>
            <p>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TEXT2_AUDI)}</p>
            <p>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TEXT3_AUDI)}</p>
          </>
        ) : (
          <>
            <p>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TEXT1_VW)}</p>
            <p>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TEXT2_VW)}</p>
            <p>{i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TEXT3_VW)}</p>
          </>
        )}
      </div>
    </div>
  );
};

ACH.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default ACH;
