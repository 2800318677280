import React from 'react';
import * as PropTypes from 'prop-types';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';

const CaliforniaPrivacyNotice: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  return (
    <div>
      <div className='privacy-content'>
        <br />
        <p className=''>{i18n.t(rk.C_PRIVACY_PARAGRAPH1)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.C_PRIVACY_PARAGRAPH2)}</p>

        <div className='california-notice'>
          {/* unit 1 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_1)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT1_P1)}</p>
          <p className='notice-paragraph'>
            {i18n.t(rk.C_NOTICE_UNIT1_P2_1)}
            <a
              href='https://privacy.vwgoa.com/'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='privacy webiste Volkswagen Group of America'
            >
              privacy.vwgoa.com
            </a>
            {i18n.t(rk.C_NOTICE_UNIT1_P2_2)}
          </p>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT1_P3)}</p>

          {/* unit 2 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_2)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT2_P1)}</p>
          <ul className='notice-paragraph'>
            <li className='list-item'>
              <b>{i18n.t(rk.C_NOTICE_UNIT2_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.C_NOTICE_UNIT2_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.C_NOTICE_UNIT2_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.C_NOTICE_UNIT2_LISTITEM2_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT2_P3)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT2_P4)}</p>
          <p className='notice-paragraph'>
            {i18n.t(rk.C_NOTICE_UNIT2_P5_1)}
            <a
              href='https://privacy.vwgoa.com/'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='privacy webiste Volkswagen Group of America'
            >
              privacy.vwgoa.com
            </a>
            {i18n.t(rk.C_NOTICE_UNIT2_P5_2)}
          </p>

          {/* unit 3 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_3)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT3_P1)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT3_P2)}</p>
          <p className='notice-paragraph'>
            {i18n.t(rk.C_NOTICE_UNIT3_P3_1)}
            <a
              href='https://privacy.vwgoa.com/'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='privacy webiste Volkswagen Group of America'
            >
              privacy.vwgoa.com
            </a>
            {i18n.t(rk.C_NOTICE_UNIT3_P3_2)}
          </p>

          {/* unit 4 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_4)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT4_P1)}</p>
          {/* unit 5 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_5)}</span>
          <ul className='response-timing'>
            <li className='list-item'>
              <b>{i18n.t(rk.C_NOTICE_UNIT5_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.C_NOTICE_UNIT5_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.C_NOTICE_UNIT5_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.C_NOTICE_UNIT5_LISTITEM2_CONTENT)}
            </li>
          </ul>
          {/* unit 6 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_6)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT6_P1)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT6_P2)}</p>

          {/* unit 7 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_7)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT7_P1)}</p>

          {/* unit 8 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_8)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT8_P1)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT8_P2)}</p>
          <table>
            <tr>
              <th className='u-p-xsmall'>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_TITLE)}</th>
              <th className='u-p-xsmall'>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN2_TITLE)}</th>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW1)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT1)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT2)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT3)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT4)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT5)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT6)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT7)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT8)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT9)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT10)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT11)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT12)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT13)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT14)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT15)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT16)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT17)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW2)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT1)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT2)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT3)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT4)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT5)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT6)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT8)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT9)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT10)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT11)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT12)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT13)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT14)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT15)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT16)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT17)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW3)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT1)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT2)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT3)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT4)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT5)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT6)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT7)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT8)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT9)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT10)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT11)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT12)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT13)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT14)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT15)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT16)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT17)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT18)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW4)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT6)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW5)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT6)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW6)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT3)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT11)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT12)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW7)}</b>
              </td>
              <td className='u-p-xsmall'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT19)}</td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW8)}</b>
              </td>
              <td>
                <ul className='list-wrap u-p-xsmall'>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT4)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT7)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT9)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT14)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT15)}</li>
                  <li className='list-item'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT17)}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW9)}</b>
              </td>
              <td className='u-p-xsmall'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT20)}</td>
            </tr>
            <tr>
              <td className='u-text-center u-p-xsmall'>
                <b>{i18n.t(rk.C_NOTICE_TABLE1_COLUMN1_ROW10)}</b>
              </td>
              <td className='u-p-xsmall'>{i18n.t(rk.C_NOTICE_TABLE1_GENERAL_CONTENT21)}</td>
            </tr>
          </table>
          {/* unit 9 */}
          <span className='privacy-notice-subtitle'>{i18n.t(rk.C_NOTICE_SUBTITLE_9)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.C_NOTICE_UNIT9_P1)}</p>
        </div>
      </div>
    </div>
  );
};

CaliforniaPrivacyNotice.propTypes = {
  brand: PropTypes.string.isRequired,
};
export default CaliforniaPrivacyNotice;
