import React, { useEffect } from 'react';
import { string } from 'prop-types';
import { Accordion } from '@vwfs-its/bronson-react';
import { useHistory } from 'react-router-dom';
import i18n from '../../../localization/i18n';
import * as rk from '../../../localization/resourceKeys';
import PrivacyNotice from '../PrivacyNotice';
import OnlineAccessAndUsageAgreement from '../OnlineAccessAndUsageAgreement';
import ACH from '../ACH';
import Logo from '../logo-component/Logo';
import Authorization from '../Authorization';
import SideBar from '../sidebar/SideBar';
import Footer from '../Footer';
import './TermsAndCondition.scss';
import { Brands, Countries, Languages } from '../../../enums';
import { BrandType, CountryType } from '../../../types';
import * as routePaths from '../../../RoutePaths';
import iconimage from '../../../assets/icons/external-link-small.svg';
import SMSTermsOfUse from '../SMSTermsOfUse';

const TermsAndCondition: React.FC<{ country: Countries; brand: Brands; page: string }> = (props) => {
  const { country, brand, page } = props;
  const brandClassName = brand === Brands.AUDI ? 'audi' : 'vw6';
  const PRIVACY_CONST = i18n.t(rk.PRIVACYNOTICE_TITLE);
  const ONLINE_CONST = i18n.t(rk.ONLINE_ACCESS_TITLE);
  const ACH_CONST = i18n.t(rk.ACH_PAYMENT_AUTHORIZATION_TITLE);
  const SMS_CONST = i18n.t(rk.SMS_TERMSOFSERVICE_TITLE);
  const AUTOMATIC_CONST = i18n.t(rk.AUTOMATIC_PAYMENT_PLAN_TITLE);

  const PRIVACY_POLICY_CONST = i18n.t(rk.PRIVACY_POLICY_TITLE);
  const USAGE_AGREEMENT_CONST = i18n.t(rk.USAGE_AGREEMENT_TITLE);
  const myAudiLoginUrl = 'https://www.audiusa.com/us/web/en/myaudi/redirect.html';
  const { language } = i18n;
  const hyphen = '-';
  const hyphenPosition = 2;
  let languageValue = language;

  // Language value should accept fr-CA or frCA format
  if (languageValue.length < 5)
    languageValue = [language.slice(0, hyphenPosition), hyphen, language.slice(hyphenPosition)].join('');
  const accordionDetails =
    country === Countries.Canada
      ? [PRIVACY_POLICY_CONST, USAGE_AGREEMENT_CONST]
      : [PRIVACY_CONST, ONLINE_CONST, ACH_CONST, AUTOMATIC_CONST, SMS_CONST];
  const routingDetails = {
    [PRIVACY_CONST]: 'privacy',
    [ONLINE_CONST]: 'OnlineAccess',
    [ACH_CONST]: 'ach',
    [SMS_CONST]: 'sms',
    [AUTOMATIC_CONST]: 'autopay',
    [PRIVACY_POLICY_CONST]: 'privacy',
    [USAGE_AGREEMENT_CONST]: 'OnlineAccess',
  };
  const history = useHistory();

  const getBasePath = (countryProperty: Countries, brandProperty: Brands): string => {
    let basePath = '';
    if (countryProperty === Countries.USA && brandProperty === Brands.AUDI) {
      basePath = routePaths.AUDI;
    } else if (countryProperty === Countries.USA && brandProperty === Brands.VW) {
      basePath = routePaths.VW;
    } else if (countryProperty === Countries.Canada && brandProperty === Brands.AUDI) {
      basePath = routePaths.AUDI_CANADA;
    } else if (countryProperty === Countries.Canada && brandProperty === Brands.VW) {
      basePath = routePaths.VW_CANADA;
    }
    return basePath;
  };
  const basePath = getBasePath(country, brand);
  const jumpToHash = () => {
    if (page !== 'default') {
      const targetDiv = document.getElementById(page);
      if (targetDiv !== null) {
        targetDiv.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
        document.querySelector(`.nav-item.${page}`)?.classList.add('active-nav');
      }
    }
  };
  useEffect(() => {
    setTimeout(() => {
      jumpToHash();
    }, 800);
    window.addEventListener('scroll', () => {
      if (window.innerWidth <= 960) return;
      Object.values(routingDetails).forEach((value) => {
        const element = document.querySelector(`#scroll-${value}`);

        if (element !== null) {
          const position = element.getBoundingClientRect();
          if (position.top < 120 && position.bottom > 100) {
            document.querySelector('.nav-item.scroll-active')?.classList.remove('scroll-active');
            document.querySelector(`.nav-item.${page}`)?.classList.remove('active-nav');
            document.querySelector(`.navlink-${value}`)?.classList.add('scroll-active');
          }
        }
      });
    });
  });

  return (
    <main className={brandClassName}>
      <Logo country={country} brand={brand} language={languageValue as Languages} />
      <div className='flex-container tandc-desktop'>
        <div className='tandc-sidebar-content'>
          <SideBar country={country} brand={brand} />
        </div>
        <div className='tandc-content'>
          <div className='tandc-detail-content'>
            <h1 className='tandc-header-container tandc-content__container'>
              {i18n.t(country === Countries.Canada ? rk.TERMS_AND_POLICIES_CANADA : rk.TERMS_AND_POLICIES)}
            </h1>
            <div id='scroll-privacy'>
              <PrivacyNotice country={country} brand={brand} />
            </div>{' '}
            <div id='scroll-OnlineAccess'>
              <OnlineAccessAndUsageAgreement country={country} brand={brand} />
            </div>
            {country === Countries.USA && (
              <>
                <div id='scroll-ach'>
                  <ACH brand={brand} />
                </div>
                <div id='scroll-autopay'>
                  <Authorization brand={brand} />
                </div>
                <div id='scroll-sms'>
                  <SMSTermsOfUse brand={brand} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <h1 className='tandc-header-mobile'>
        {i18n.t(country === Countries.Canada ? rk.TERMS_AND_POLICIES_CANADA : rk.TERMS_AND_POLICIES)}
      </h1>
      <div className='tandc-mobile'>
        <Accordion
          onChange={(value: keyof typeof routingDetails) => {
            if ((value as any)?.length > 0) {
              const route = `${basePath}/${routingDetails[value]}`;
              history.push(route);
            }
          }}
        >
          {accordionDetails.map((value, index) => (
            <Accordion.Item
              tabIndex={index}
              role='button'
              key={value}
              data-testid={`accordian-details-${index}`}
              title={value}
            >
              {index === 0 ? <PrivacyNotice country={country} brand={brand} /> : ' '}
              {index === 1 ? <OnlineAccessAndUsageAgreement country={country} brand={brand} /> : ' '}
              {country === Countries.USA && index === 2 ? <ACH brand={brand} /> : ' '}
              {country === Countries.USA && index === 3 ? <Authorization brand={brand} /> : ' '}
              {country === Countries.USA && index === 4 ? <SMSTermsOfUse brand={brand} /> : ' '}
            </Accordion.Item>
          ))}
          {country === Countries.Canada && (
            <div className='c-accordion__header  js-accordion__title' data-testid='accordian-details-4' role='button'>
              <a
                href={
                  brand === Brands.AUDI
                    ? i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA_AUDI)
                    : i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA_VW)
                }
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src={iconimage}
                  alt={i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA)}
                  className='c-accordion__title-icon-link'
                />
                <h3 className='c-accordion__title'>
                  <span
                    className='c-accordion__title-label js-accordion__title-label'
                    aria-expanded='false'
                    role='button'
                  >
                    <span className='c-accordion__title-label-text'>{i18n.t(rk.PRIVACY_PORTAL_LINK_CANADA)}</span>
                  </span>
                </h3>
              </a>
            </div>
          )}
          {country === Countries.USA && brand === Brands.AUDI && (
            <div className='c-accordion__header  js-accordion__title' data-testid='accordian-details-4' role='button'>
              <a href={myAudiLoginUrl} target='_blank' rel='noopener noreferrer'>
                <img src={iconimage} alt={i18n.t(rk.MYAUDI_LOGIN_TITLE)} className='c-accordion__title-icon-link' />
                <h3 className='c-accordion__title'>
                  <span
                    className='c-accordion__title-label js-accordion__title-label'
                    aria-expanded='false'
                    role='button'
                  >
                    <span className='c-accordion__title-label-text'>{i18n.t(rk.MYAUDI_LOGIN_TITLE)}</span>
                  </span>
                </h3>
              </a>
            </div>
          )}
        </Accordion>
      </div>
      <Footer country={country} />
    </main>
  );
};

TermsAndCondition.propTypes = {
  page: string.isRequired,
  brand: BrandType.isRequired,
  country: CountryType.isRequired,
};

export default TermsAndCondition;
