import React from 'react';
import { Brands, Countries } from '../../enums';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';
import { BrandType, CountryType } from '../../types';

const OnlineAccessAndUsageAgreementUSA: React.FC = () => {
  // ONLINE ACCESS AND USAGE AGREEMENTs are the same for Audi and VW in USA
  return (
    <div className='tandc-content__container terms-condition' id='online-access'>
      <h2 className='tandc-title-header'>{i18n.t(rk.ONLINE_ACCESS_TITLE)}</h2>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P1)}</p>
      <p className='notice-paragraph underline-bold'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P2)}</p>
      <p className='notice-paragraph underline-bold'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P3)}</p>
      <p className='notice-paragraph underline-bold'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P4)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P5)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P6)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P7)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P8)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P9)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_INTRO_P10)}</p>

      {/* unit 1 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT1_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT1_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT1_P2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT1_P3)}</p>
      {/* unit 2 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT2_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT2_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT2_P2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT2_P3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT2_P4)}</p>
      {/* unit 3 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P4)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P5)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P6)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P7)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P8)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P9)}</p>
      <p className='notice-paragraph'>
        {i18n.t(rk.ONLINE_ACCESS_UNIT3_P10_1)}
        <u>{i18n.t(rk.ONLINE_ACCESS_UNIT3_P10_2)}</u>
      </p>
      {/* unit 4 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P1)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P2)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P4)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P5)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P6)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P7)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P8)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P9)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_SUBTITLE1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P10)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_SUBTITLE2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT4_P11)}</p>

      {/* unit 5 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT5_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT5_P1)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT5_SUBTITLE1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT5_P2)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT5_SUBTITLE2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT5_P3)}</p>
      {/* unit 6 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_P2)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_SUBTITLE1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_P3)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_SUBTITLE2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT6_P4)}</p>
      {/* unit 7 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT7_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT7_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT7_P2)}</p>
      {/* unit 8 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT8_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT8_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT8_P2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT8_P3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT8_P4)}</p>
      {/* unit 9 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_P2)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_SUBTITLE1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART1_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART1_P2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART1_P3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART1_P4)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_SUBTITLE2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART2_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART2_P2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART2_P3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART2_P4)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART2_P5)}</p>
      <p className='online-access-subtitle-within'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_SUBTITLE3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART3_P1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART3_P2)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART3_P3)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART3_P4)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT9_PART3_P5)}</p>
      {/* unit 10 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT10_TITLE1)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT10_TITLE2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT10_P1)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT10_TITLE3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT10_P2)}</p>
      {/* UNIT 11 */}
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_TITLE)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P1)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P2)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P3)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P4)}</p>
      <p className='notice-paragraph-indent'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P5)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P6)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_TITLE2)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P7)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_TITLE3)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P8)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_TITLE4)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P9)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_TITLE5)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P10)}</p>
      <p className='online-access-subtitle'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_TITLE6)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.ONLINE_ACCESS_UNIT11_P11)}</p>
      {/* consent */}
      <p className='notice-paragraph'>{i18n.t(rk.ACKNOWLEDGE_CONSENT)}</p>
      <p className='notice-paragraph'>
        <b>{i18n.t(rk.CONSENT_P1)}</b>
      </p>
    </div>
  );
};

const OnlineAccessAndUsageAgreementCanada: React.FC<{ brand: Brands }> = (props) => {
  const { brand } = props;
  // ONLINE ACCESS AND USAGE AGREEMENTs are the same for Audi and VW in USA
  return (
    <div className='tandc-content__container terms-condition' id='online-access'>
      <h2 className='tandc-title-header'>{i18n.t(rk.USAGE_AGREEMENT_TITLE)}</h2>
      <p className='notice-paragraph underline-bold'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE)}</p>
      {/* intro */}
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_INTRO_PARAGRAPH1)}</p>
      <p>
        {i18n.t(rk.USAGE_AGREEMENT_INTRO_PARAGRAPH3_1)}
        <a
          href={
            brand === Brands.AUDI
              ? i18n.t(rk.USAGE_AGREEMENT_INTRO_PARAGRAPH3_LINK_AUDI)
              : i18n.t(rk.USAGE_AGREEMENT_INTRO_PARAGRAPH3_LINK_VW)
          }
        >
          {' '}
          {i18n.t(
            brand === Brands.AUDI
              ? rk.USAGE_AGREEMENT_INTRO_PARAGRAPH3_LINK_AUDI
              : rk.USAGE_AGREEMENT_INTRO_PARAGRAPH3_LINK_VW
          )}{' '}
        </a>

        {i18n.t(rk.USAGE_AGREEMENT_INTRO_PARAGRAPH3_2)}
      </p>
      <p>{i18n.t(rk.USAGE_AGREEMENT_INTRO_PARAGRAPH4)}</p>
      {/* unit 1 */}
      <span className='privacy-notice-subtitle'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE_1)}</span>
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_UNIT1_PARAGRAPH1)}</p>
      <p>{i18n.t(rk.USAGE_AGREEMENT_UNIT1_PARAGRAPH2)}</p>
      <p>{i18n.t(rk.USAGE_AGREEMENT_UNIT1_PARAGRAPH3)}</p>
      {/* unit 2 */}
      <span className='privacy-notice-subtitle'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE_2)}</span>
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_UNIT2_PARAGRAPH1)}</p>
      {/* unit 3 */}
      <span className='privacy-notice-subtitle'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE_3)}</span>
      <p className='notice-paragraph low-margin'>{i18n.t(rk.USAGE_AGREEMENT_UNIT3_PARAGRAPH1)}</p>
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_UNIT3_PARAGRAPH2)}</p>
      {/* unit 4 */}
      <span className='privacy-notice-subtitle'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE_4)}</span>
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_UNIT4_PARAGRAPH1)}</p>
      {/* unit 5 */}
      <span className='privacy-notice-subtitle'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE_5)}</span>
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_UNIT5_PARAGRAPH1)}</p>
      {/* unit 6 */}
      <span className='privacy-notice-subtitle'>{i18n.t(rk.USAGE_AGREEMENT_SUBTITLE_6)}</span>
      <p className='notice-paragraph'>{i18n.t(rk.USAGE_AGREEMENT_UNIT6_PARAGRAPH1)}</p>
    </div>
  );
};

OnlineAccessAndUsageAgreementCanada.propTypes = {
  brand: BrandType.isRequired,
};

const OnlineAccessAndUsageAgreement: React.FC<{ country: Countries; brand: Brands }> = (props) => {
  const { country, brand } = props;
  return country === Countries.Canada ? (
    <OnlineAccessAndUsageAgreementCanada brand={brand} />
  ) : (
    <OnlineAccessAndUsageAgreementUSA />
  );
};

OnlineAccessAndUsageAgreement.propTypes = {
  country: CountryType.isRequired,
  brand: BrandType.isRequired,
};

export default OnlineAccessAndUsageAgreement;
