import React from 'react';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';

const RepresentativeOfBusiness = () => {
  return (
    <div>
      <div className='privacy-content'>
        <br />
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT1_PARAGRAPH1)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT1_PARAGRAPH2)}</p>
        <p className='notice-paragraph low-margin'>
          <u>
            <b>{i18n.t(rk.B2B_POLICY_UNIT1_PARAGRAPH3_1)}</b>
          </u>
          {i18n.t(rk.B2B_POLICY_UNIT1_PARAGRAPH3)}
        </p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT2_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT2_PARAGRAPH1)}</p>
        <ul className='list-wrap'>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT2_P1_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT2_P1_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT2_P2_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT2_P2_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT2_P3_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT2_P3_TEXT)}
          </li>
        </ul>
        <p className='notice-paragraph'>{i18n.t(rk.B2B_POLICY_UNIT2_PARAGRAPH2)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT3_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT3_PARAGRAPH1)}</p>
        <ol type='A' className='list-wrap'>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT3_P1_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT3_P1_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT3_P2_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT3_P2_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT3_P3_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT3_P3_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT3_P4_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT3_P4_TEXT)}
          </li>
        </ol>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT4_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT4_PARAGRAPH1)}</p>
        <ol type='A' className='list-wrap'>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT4_P1_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT4_P1_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT4_P2_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT4_P2_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT4_P3_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT4_P3_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT4_P4_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT4_P4_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT4_P5_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT4_P5_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT4_P6_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT4_P6_TEXT)}
          </li>
        </ol>
        <p className='notice-paragraph'>{i18n.t(rk.B2B_POLICY_UNIT4_PARAGRAPH2)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT5_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT5_PARAGRAPH1)}</p>
        <ol type='A' className='list-wrap'>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P1_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT5_P1_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P2_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT5_P2_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P3_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT5_P3_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P4_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT5_P4_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P5_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT5_P5_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P6_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT5_P6_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P7_TITLE)}</b>
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT5_P8_TITLE)}</b>
          </li>
        </ol>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT6_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT6_PARAGRAPH1)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT6_PARAGRAPH2)}</p>
        <ul className='list-wrap'>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P1_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P2_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P3_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P4_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P5_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P6_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P7_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P8_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P9_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P10_TITLE)}</li>
          <li className='list-item'>{i18n.t(rk.B2B_POLICY_UNIT6_P11_TITLE)}</li>
        </ul>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT6_1_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT6_1_PARAGRAPH1)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT6_1_PARAGRAPH2)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT7_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT7_PARAGRAPH1)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT7_PARAGRAPH2)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT8_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT8_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT9_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT9_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT10_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT10_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT11_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT11_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT12_TITLE)}</p>
        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT12_1_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH1)}</p>
        <ul className='list-wrap'>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT12_P1_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT12_P1_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT12_P2_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT12_P2_TEXT)}
          </li>
          <li className='list-item'>
            <b>{i18n.t(rk.B2B_POLICY_UNIT12_P3_TITLE)}</b>
            {i18n.t(rk.B2B_POLICY_UNIT12_P3_TEXT)}
          </li>
        </ul>
        <p className='notice-paragraph'>
          {i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH2_1)}
          <a
            href='https://privacy.vwgoa.com/'
            rel='noopener noreferrer'
            target='_blank'
            className=''
            aria-label='privacy webiste Volkswagen Group of America'
          >
            privacy.vwgoa.com
          </a>
          {i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH2_2)}
        </p>
        <p className='notice-paragraph low-margin'>
          <u>{i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH3_1)}</u>
          {i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH3)}
        </p>
        <p className='notice-paragraph low-margin'>
          <u>{i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH4_1)}</u>
          {i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH4)}
        </p>
        <p className='notice-paragraph low-margin'>
          <u>{i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH5_1)}</u>
          {i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH5)}
        </p>
        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT12_2_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT12_PARAGRAPH6)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT13_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT13_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT14_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT14_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT15_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT15_PARAGRAPH1)}</p>

        <p className='privacy-notice-subtitle'>{i18n.t(rk.B2B_POLICY_UNIT16_TITLE)}</p>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.B2B_POLICY_UNIT16_PARAGRAPH1)}</p>

        <table>
          <tr>
            <th className='u-p-xsmall'>{i18n.t(rk.B2B_POLICY_TABLE1_COLUMN1_TITLE)}</th>
            <th className='u-p-xsmall'>{i18n.t(rk.B2B_POLICY_TABLE1_COLUMN2_TITLE)}</th>
          </tr>
          <tr>
            <td className='u-text-center u-p-xsmall'>
              <b>{i18n.t(rk.B2B_POLICY_TABLE1_COLUMN1_ROW1)}</b>
            </td>
            <td>
              <ul className='list-wrap u-p-xsmall'>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT1)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT2)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT3)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT4)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT5)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT6)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT7)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT8)}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className='u-text-center u-p-xsmall'>
              <b>{i18n.t(rk.B2B_POLICY_TABLE1_COLUMN1_ROW2)}</b>
            </td>
            <td>
              <ul className='list-wrap u-p-xsmall'>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT1)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT2)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT3)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT4)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT5)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT6)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT7)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT8)}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className='u-text-center u-p-xsmall'>
              <b>{i18n.t(rk.B2B_POLICY_TABLE1_COLUMN1_ROW3)}</b>
            </td>
            <td>
              <ul className='list-wrap u-p-xsmall'>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT1)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT2)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT3)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT4)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT5)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT6)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT7)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT8)}</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td className='u-text-center u-p-xsmall'>
              <b>{i18n.t(rk.B2B_POLICY_TABLE1_COLUMN1_ROW4)}</b>
            </td>
            <td>
              <ul className='list-wrap u-p-xsmall'>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT1)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT2)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT3)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT4)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT5)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT6)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT7)}</li>
                <li className='list-item'>{i18n.t(rk.B2B_POLICY_TABLE1_GENERAL_CONTENT8)}</li>
              </ul>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default RepresentativeOfBusiness;
