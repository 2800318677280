export const TERMS_AND_CONDITIONS = 'TermsAndConditions';
export const TERMS_AND_POLICIES = 'TermsAndPoliciesTitle';

//  Privacy Notice
export const PRIVACYNOTICE_TITLE = 'PrivacyNotice.Title';
export const PRIVACYNOTICE_TITLE_ONLINE_PRIVACY_NOTICE_HEADER_CA = 'PrivacyNotice.Title.OnlinePrivacyNoticeHeaderCA';
export const PRIVACYNOTICE_TITLE_PRIVACY_ACT_RIGHTS = 'PrivacyNotice.Title.PrivacyActRights';

//  Online Privacy Notice
export const ONLINE_PRIVACY_NOTICE_TITLE = 'Online.Privacy.Notice.Title';
export const PERSONNEL_PRIVACY_NOTICE_TITLE = 'Personnel.Privacy.Notice.Title';
export const CALIFORNIA_PRIVACY_NOTICE_TITLE = 'California.Privacy.Notice.Title';
export const PRIVACY_NOTICE_DESCRIPTION = 'Privacy.Notice.Description';
export const PRIVACY_NOTICE_EFFECTIVE_DATE = 'Privacy.Notice.Effective.Date';
export const PRIVACY_NOTICE_LAST_UPDATED = 'Privacy.Notice.Last.Updated';
export const PRIVACY_NOTICE_SUBTITLE_1 = 'Privacy.Notice.Subtitle.1';
export const PRIVACY_NOTICE_UNIT1_PARAGRAPH1 = 'Privacy.Notice.Unit1.Paragraph1';
export const VW_PRIVACY_NOTICE_UNIT1_PARAGRAPH1 = 'VW_Privacy.Notice.Unit1.Paragraph1';
export const PRIVACY_NOTICE_UNIT1_PARAGRAPH2 = 'Privacy.Notice.Unit1.Paragraph2';
export const PRIVACY_NOTICE_UNIT1_PARAGRAPH3 = 'Privacy.Notice.Unit1.Paragraph3';
export const PRIVACY_NOTICE_UNIT1_PARAGRAPH3_1 = 'Privacy.Notice.Unit1.Paragraph3.1';
export const PRIVACY_NOTICE_UNIT1_PARAGRAPH3_2 = 'Privacy.Notice.Unit1.Paragraph3.2';
export const PRIVACY_NOTICE_UNIT1_PARAGRAPH4 = 'Privacy.Notice.Unit1.Paragraph4';
export const PRIVACY_NOTICE_SUBTITLE_2 = 'Privacy.Notice.Subtitle.2';
export const PRIVACY_NOTICE_UNIT2_PARAGRAPH1 = 'Privacy.Notice.Unit2.Paragraph1';
export const PRIVACY_NOTICE_UNIT2_LISTITEM1_TITLE = 'Privacy.Notice.Unit2.ListItem1.Title';
export const PRIVACY_NOTICE_UNIT2_LISTITEM1_CONTENT = 'Privacy.Notice.Unit2.ListItem1.Content';
export const PRIVACY_NOTICE_UNIT2_LISTITEM2_TITLE = 'Privacy.Notice.Unit2.ListItem2.Title';
export const PRIVACY_NOTICE_UNIT2_LISTITEM2_CONTENT = 'Privacy.Notice.Unit2.ListItem2.Content';
export const PRIVACY_NOTICE_UNIT2_LISTITEM3_TITLE = 'Privacy.Notice.Unit2.ListItem3.Title';
export const PRIVACY_NOTICE_UNIT2_LISTITEM3_CONTENT = 'Privacy.Notice.Unit2.ListItem3.Content';
export const PRIVACY_NOTICE_UNIT2_LISTITEM4_TITLE = 'Privacy.Notice.Unit2.ListItem4.Title';
export const PRIVACY_NOTICE_UNIT2_LISTITEM4_CONTENT = 'Privacy.Notice.Unit2.ListItem4.Content';
export const PRIVACY_NOTICE_UNIT2_LISTITEM5_TITLE = 'Privacy.Notice.Unit2.ListItem5.Title';
export const PRIVACY_NOTICE_UNIT2_LISTITEM5_CONTENT = 'Privacy.Notice.Unit2.ListItem5.Content';
export const PRIVACY_NOTICE_UNIT2_LISTITEM6_TITLE = 'Privacy.Notice.Unit2.ListItem6.Title';
export const PRIVACY_NOTICE_UNIT2_LISTITEM6_CONTENT = 'Privacy.Notice.Unit2.ListItem6.Content';
export const PRIVACY_NOTICE_SUBTITLE_3 = 'Privacy.Notice.Subtitle.3';
export const PRIVACY_NOTICE_UNIT3_PARAGRAPH1 = 'Privacy.Notice.Unit3.Paragraph1';
export const PRIVACY_NOTICE_UNIT3_LISTITEM1_TITLE = 'Privacy.Notice.Unit3.ListItem1.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM1_CONTENT = 'Privacy.Notice.Unit3.ListItem1.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM2_TITLE = 'Privacy.Notice.Unit3.ListItem2.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM2_CONTENT = 'Privacy.Notice.Unit3.ListItem2.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM3_TITLE = 'Privacy.Notice.Unit3.ListItem3.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM3_CONTENT = 'Privacy.Notice.Unit3.ListItem3.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM4_TITLE = 'Privacy.Notice.Unit3.ListItem4.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM4_CONTENT = 'Privacy.Notice.Unit3.ListItem4.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM5_TITLE = 'Privacy.Notice.Unit3.ListItem5.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM5_CONTENT = 'Privacy.Notice.Unit3.ListItem5.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM6_TITLE = 'Privacy.Notice.Unit3.ListItem6.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM6_CONTENT = 'Privacy.Notice.Unit3.ListItem6.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM7_TITLE = 'Privacy.Notice.Unit3.ListItem7.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM7_CONTENT = 'Privacy.Notice.Unit3.ListItem7.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM8_TITLE = 'Privacy.Notice.Unit3.ListItem8.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM8_CONTENT = 'Privacy.Notice.Unit3.ListItem8.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM9_TITLE = 'Privacy.Notice.Unit3.ListItem9.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM9_CONTENT = 'Privacy.Notice.Unit3.ListItem9.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM10_TITLE = 'Privacy.Notice.Unit3.ListItem10.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM10_CONTENT = 'Privacy.Notice.Unit3.ListItem10.Content';
export const PRIVACY_NOTICE_UNIT3_LISTITEM11_TITLE = 'Privacy.Notice.Unit3.ListItem11.Title';
export const PRIVACY_NOTICE_UNIT3_LISTITEM11_CONTENT = 'Privacy.Notice.Unit3.ListItem11.Content';
export const PRIVACY_NOTICE_UNIT3_PARAGRAPH2 = 'Privacy.Notice.Unit3.Paragraph2';
export const PRIVACY_NOTICE_SUBTITLE_4 = 'Privacy.Notice.Subtitle.4';
export const PRIVACY_NOTICE_UNIT4_PARAGRAPH1 = 'Privacy.Notice.Unit4.Paragraph1';
export const PRIVACY_NOTICE_UNIT4_LISTITEM1_TITLE = 'Privacy.Notice.Unit4.ListItem1.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM1_CONTENT = 'Privacy.Notice.Unit4.ListItem1.Content';
export const PRIVACY_NOTICE_UNIT4_LISTITEM2_TITLE = 'Privacy.Notice.Unit4.ListItem2.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM2_CONTENT = 'Privacy.Notice.Unit4.ListItem2.Content';
export const PRIVACY_NOTICE_UNIT4_LISTITEM3_TITLE = 'Privacy.Notice.Unit4.ListItem3.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM3_CONTENT = 'Privacy.Notice.Unit4.ListItem3.Content';
export const PRIVACY_NOTICE_UNIT4_LISTITEM4_TITLE = 'Privacy.Notice.Unit4.ListItem4.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM4_CONTENT = 'Privacy.Notice.Unit4.ListItem4.Content';
export const PRIVACY_NOTICE_UNIT4_LISTITEM5_TITLE = 'Privacy.Notice.Unit4.ListItem5.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM5_CONTENT = 'Privacy.Notice.Unit4.ListItem5.Content';
export const PRIVACY_NOTICE_UNIT4_LISTITEM6_TITLE = 'Privacy.Notice.Unit4.ListItem6.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM6_CONTENT = 'Privacy.Notice.Unit4.ListItem6.Content';
export const PRIVACY_NOTICE_UNIT4_LISTITEM7_TITLE = 'Privacy.Notice.Unit4.ListItem7.Title';
export const PRIVACY_NOTICE_UNIT4_LISTITEM7_CONTENT = 'Privacy.Notice.Unit4.ListItem7.Content';
export const PRIVACY_NOTICE_UNIT4_PARAGRAPH2 = 'Privacy.Notice.Unit4.Paragraph2';
export const PRIVACY_NOTICE_UNIT4_PARAGRAPH3 = 'Privacy.Notice.Unit4.Paragraph3';
export const PRIVACY_NOTICE_SUBTITLE_5 = 'Privacy.Notice.Subtitle.5';
export const PRIVACY_NOTICE_UNIT5_PARAGRAPH1 = 'Privacy.Notice.Unit5.Paragraph1';
export const PRIVACY_NOTICE_UNIT5_PARAGRAPH2 = 'Privacy.Notice.Unit5.Paragraph2';
export const PRIVACY_NOTICE_UNIT5_LISTITEM1_TITLE = 'Privacy.Notice.Unit5.ListItem1.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM1_CONTENT = 'Privacy.Notice.Unit5.ListItem1.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM2_TITLE = 'Privacy.Notice.Unit5.ListItem2.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM2_CONTENT = 'Privacy.Notice.Unit5.ListItem2.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM3_TITLE = 'Privacy.Notice.Unit5.ListItem3.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM3_CONTENT = 'Privacy.Notice.Unit5.ListItem3.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM4_TITLE = 'Privacy.Notice.Unit5.ListItem4.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM4_CONTENT = 'Privacy.Notice.Unit5.ListItem4.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM5_TITLE = 'Privacy.Notice.Unit5.ListItem5.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM5_CONTENT = 'Privacy.Notice.Unit5.ListItem5.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM6_TITLE = 'Privacy.Notice.Unit5.ListItem6.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM6_CONTENT = 'Privacy.Notice.Unit5.ListItem6.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM7_TITLE = 'Privacy.Notice.Unit5.ListItem7.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM7_CONTENT = 'Privacy.Notice.Unit5.ListItem7.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM8_TITLE = 'Privacy.Notice.Unit5.ListItem8.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM8_CONTENT = 'Privacy.Notice.Unit5.ListItem8.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM9_TITLE = 'Privacy.Notice.Unit5.ListItem9.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM9_CONTENT = 'Privacy.Notice.Unit5.ListItem9.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM10_TITLE = 'Privacy.Notice.Unit5.ListItem10.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM10_CONTENT = 'Privacy.Notice.Unit5.ListItem10.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM11_TITLE = 'Privacy.Notice.Unit5.ListItem11.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM11_CONTENT = 'Privacy.Notice.Unit5.ListItem11.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM12_TITLE = 'Privacy.Notice.Unit5.ListItem12.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM12_CONTENT = 'Privacy.Notice.Unit5.ListItem12.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM13_TITLE = 'Privacy.Notice.Unit5.ListItem13.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM13_CONTENT = 'Privacy.Notice.Unit5.ListItem13.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM14_TITLE = 'Privacy.Notice.Unit5.ListItem14.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM14_CONTENT = 'Privacy.Notice.Unit5.ListItem14.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM15_TITLE = 'Privacy.Notice.Unit5.ListItem15.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM15_CONTENT = 'Privacy.Notice.Unit5.ListItem15.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM16_TITLE = 'Privacy.Notice.Unit5.ListItem16.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM16_CONTENT = 'Privacy.Notice.Unit5.ListItem16.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM17_TITLE = 'Privacy.Notice.Unit5.ListItem17.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM17_CONTENT = 'Privacy.Notice.Unit5.ListItem17.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM18_TITLE = 'Privacy.Notice.Unit5.ListItem18.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM18_CONTENT = 'Privacy.Notice.Unit5.ListItem18.Content';
export const PRIVACY_NOTICE_UNIT5_LISTITEM19_TITLE = 'Privacy.Notice.Unit5.ListItem19.Title';
export const PRIVACY_NOTICE_UNIT5_LISTITEM19_CONTENT = 'Privacy.Notice.Unit5.ListItem19.Content';
export const PRIVACY_NOTICE_SUBTITLE_6 = 'Privacy.Notice.Subtitle.6';
export const PRIVACY_NOTICE_UNIT6_PARAGRAPH1 = 'Privacy.Notice.Unit6.Paragraph1';
export const PRIVACY_NOTICE_UNIT6_PARAGRAPH2 = 'Privacy.Notice.Unit6.Paragraph2';
export const PRIVACY_NOTICE_UNIT6_PARAGRAPH3 = 'Privacy.Notice.Unit6.Paragraph3';
export const PRIVACY_NOTICE_SUBTITLE_7 = 'Privacy.Notice.Subtitle.7';
export const PRIVACY_NOTICE_UNIT7_PARAGRAPH1 = 'Privacy.Notice.Unit7.Paragraph1';
export const PRIVACY_NOTICE_SUBTITLE_8 = 'Privacy.Notice.Subtitle.8';
export const PRIVACY_NOTICE_UNIT8_PARAGRAPH1 = 'Privacy.Notice.Unit8.Paragraph1';
export const PRIVACY_NOTICE_SUBTITLE_9 = 'Privacy.Notice.Subtitle.9';
export const PRIVACY_NOTICE_UNIT9_PARAGRAPH1 = 'Privacy.Notice.Unit9.Paragraph1';
export const PRIVACY_NOTICE_UNIT9_PARAGRAPH2_1 = 'Privacy.Notice.Unit9.Paragraph2.1';
export const PRIVACY_NOTICE_UNIT9_PARAGRAPH2_2 = 'Privacy.Notice.Unit9.Paragraph2.2';
export const VW_PRIVACY_NOTICE_UNIT9_PARAGRAPH2_2 = 'VW_Privacy.Notice.Unit9.Paragraph2.2';
export const IF_CALIFORINA_RESIDENT = 'Privacy.Notice.If.Californian';
export const IF_OTHER_STATE_RESIDENT = 'Privacy.Notice.If.Other.State';
export const VIEW_PRINT_PRIVACY_NOTICE = 'View.Print.Privacy.Notice';
export const PRIVACY_NOTICE_SUBTITLE_10 = 'Privacy.Notice.Subtitle.10';
export const PRIVACY_NOTICE_UNIT10_PARAGRAPH1 = 'Privacy.Notice.Unit10.Paragraph1';
export const PRIVACY_NOTICE_UNIT10_PARAGRAPH2 = 'Privacy.Notice.Unit10.Paragraph2';
export const PRIVACY_NOTICE_UNIT10_LISTITEM1_CONTENT = 'Privacy.Notice.Unit10.ListItem1.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM2_CONTENT = 'Privacy.Notice.Unit10.ListItem2.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM3_CONTENT = 'Privacy.Notice.Unit10.ListItem3.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM4_CONTENT = 'Privacy.Notice.Unit10.ListItem4.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM5_CONTENT = 'Privacy.Notice.Unit10.ListItem5.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM6_CONTENT = 'Privacy.Notice.Unit10.ListItem6.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM7_CONTENT = 'Privacy.Notice.Unit10.ListItem7.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM8_CONTENT = 'Privacy.Notice.Unit10.ListItem8.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM9_CONTENT = 'Privacy.Notice.Unit10.ListItem9.Content';
export const PRIVACY_NOTICE_UNIT10_LISTITEM10_CONTENT = 'Privacy.Notice.Unit10.ListItem10.Content';
export const DO_NOT_TRACK = 'Do.Not.Track';
export const DO_NOT_TRACK_PARAGRAPH_1 = 'Do.Not.Track.p1';
export const DO_NOT_TRACK_PARAGRAPH_2 = 'Do.Not.Track.p2';
export const DO_NOT_TRACK_PARAGRAPH_3 = 'Do.Not.Track.p3';
export const PRIVACY_NOTICE_SUBTITLE_11 = 'Privacy.Notice.Subtitle.11';
export const PRIVACY_NOTICE_UNIT11_PARAGRAPH1 = 'Privacy.Notice.Unit11.Paragraph1';
export const PRIVACY_NOTICE_UNIT11_PARAGRAPH2 = 'Privacy.Notice.Unit11.Paragraph2';
export const PRIVACY_NOTICE_SUBTITLE_12 = 'Privacy.Notice.Subtitle.12';
export const PRIVACY_NOTICE_UNIT12_PARAGRAPH1 = 'Privacy.Notice.Unit12.Paragraph1';
export const VW_PRIVACY_NOTICE_UNIT12_PARAGRAPH1 = 'VW_Privacy.Notice.Unit12.Paragraph1';
export const PERSONNEL_PRIVACY_NOTICE_P1 = 'Personnel.Privacy.Notice.p1';
export const PERSONNEL_PRIVACY_NOTICE_LINK = 'Personnel.Privacy.Notice.link';
//  California privacy notice
export const C_PRIVACY_PARAGRAPH1 = 'California.Privacy.Paragraph.1';
export const C_PRIVACY_PARAGRAPH2 = 'California.Privacy.Paragraph.2';
export const C_NOTICE_SUBTITLE_1 = 'California.Notice.Subtitle.1';
export const C_NOTICE_UNIT1_P1 = 'California.Notice.Unit1.p1';
export const C_NOTICE_UNIT1_P2_1 = 'California.Notice.Unit1.p2.1';
export const C_NOTICE_UNIT1_P2_2 = 'California.Notice.Unit1.p2.2';
export const C_NOTICE_UNIT1_P3 = 'California.Notice.Unit1.p3';
export const C_NOTICE_UNIT1_P4_1 = 'California.Notice.Unit1.p4.1';
export const C_NOTICE_UNIT1_P4_2 = 'California.Notice.Unit1.p4.2';
export const VW_C_NOTICE_UNIT1_P4_2 = 'VW_California.Notice.Unit1.p4.2';
export const C_NOTICE_SUBTITLE_2 = 'California.Notice.Subtitle.2';
export const C_NOTICE_UNIT2_P1 = 'California.Notice.Unit2.p1';
export const C_NOTICE_UNIT2_P2_1 = 'California.Notice.Unit2.p2.1';
export const C_NOTICE_UNIT2_P2_2 = 'California.Notice.Unit2.p2.2';
export const C_NOTICE_UNIT2_LISTITEM1_TITLE = 'California.Notice.Unit2.ListItem1.Title';
export const C_NOTICE_UNIT2_LISTITEM1_CONTENT = 'California.Notice.Unit2.ListItem1.Content';
export const C_NOTICE_UNIT2_LISTITEM2_TITLE = 'California.Notice.Unit2.ListItem2.Title';
export const C_NOTICE_UNIT2_LISTITEM2_CONTENT = 'California.Notice.Unit2.ListItem2.Content';
export const C_NOTICE_UNIT2_P3 = 'California.Notice.Unit2.p3';
export const C_NOTICE_UNIT2_P4 = 'California.Notice.Unit2.p4';
export const C_NOTICE_UNIT2_P5_1 = 'California.Notice.Unit2.p5.1';
export const C_NOTICE_UNIT2_P5_2 = 'California.Notice.Unit2.p5.2';
export const C_NOTICE_SUBTITLE_3 = 'California.Notice.Subtitle.3';
export const C_NOTICE_UNIT3_P1 = 'California.Notice.Unit3.p1';
export const C_NOTICE_UNIT3_P2 = 'California.Notice.Unit3.p2';
export const C_NOTICE_UNIT3_P3_1 = 'California.Notice.Unit3.p3.1';
export const C_NOTICE_UNIT3_P3_2 = 'California.Notice.Unit3.p3.2';
export const C_NOTICE_SUBTITLE_4 = 'California.Notice.Subtitle.4';
export const C_NOTICE_UNIT4_P1 = 'California.Notice.Unit4.p1';
export const C_NOTICE_SUBTITLE_5 = 'California.Notice.Subtitle.5';
export const C_NOTICE_UNIT5_LISTITEM1_TITLE = 'California.Notice.Unit5.ListItem1.Title';
export const C_NOTICE_UNIT5_LISTITEM1_CONTENT = 'California.Notice.Unit5.ListItem1.Content';
export const C_NOTICE_UNIT5_LISTITEM2_TITLE = 'California.Notice.Unit5.ListItem2.Title';
export const C_NOTICE_UNIT5_LISTITEM2_CONTENT = 'California.Notice.Unit5.ListItem2.Content';
export const C_NOTICE_SUBTITLE_6 = 'California.Notice.Subtitle.6';
export const C_NOTICE_UNIT6_P1 = 'California.Notice.Unit6.p1';
export const C_NOTICE_UNIT6_P2 = 'California.Notice.Unit6.p2';
export const C_NOTICE_SUBTITLE_7 = 'California.Notice.Subtitle.7';
export const C_NOTICE_UNIT7_P1 = 'California.Notice.Unit7.p1';
export const C_NOTICE_SUBTITLE_8 = 'California.Notice.Subtitle.8';
export const C_NOTICE_UNIT8_P1 = 'California.Notice.Unit8.p1';
export const C_NOTICE_UNIT8_P2 = 'California.Notice.Unit8.p2';
export const C_NOTICE_TABLE1_COLUMN1_TITLE = 'California.Notice.Table1.Column1.Title';
export const C_NOTICE_TABLE1_COLUMN2_TITLE = 'California.Notice.Table1.Column2.Title';
export const C_NOTICE_TABLE1_COLUMN1_ROW1 = 'California.Notice.Table1.Column1.Row1';
export const C_NOTICE_TABLE1_COLUMN1_ROW2 = 'California.Notice.Table1.Column1.Row2';
export const C_NOTICE_TABLE1_COLUMN1_ROW3 = 'California.Notice.Table1.Column1.Row3';
export const C_NOTICE_TABLE1_COLUMN1_ROW4 = 'California.Notice.Table1.Column1.Row4';
export const C_NOTICE_TABLE1_COLUMN1_ROW5 = 'California.Notice.Table1.Column1.Row5';
export const C_NOTICE_TABLE1_COLUMN1_ROW6 = 'California.Notice.Table1.Column1.Row6';
export const C_NOTICE_TABLE1_COLUMN1_ROW7 = 'California.Notice.Table1.Column1.Row7';
export const C_NOTICE_TABLE1_COLUMN1_ROW8 = 'California.Notice.Table1.Column1.Row8';
export const C_NOTICE_TABLE1_COLUMN1_ROW9 = 'California.Notice.Table1.Column1.Row9';
export const C_NOTICE_TABLE1_COLUMN1_ROW10 = 'California.Notice.Table1.Column1.Row10';
export const C_NOTICE_TABLE1_GENERAL_CONTENT1 = 'California.Notice.Table1.GeneralContent1';
export const C_NOTICE_TABLE1_GENERAL_CONTENT2 = 'California.Notice.Table1.GeneralContent2';
export const C_NOTICE_TABLE1_GENERAL_CONTENT3 = 'California.Notice.Table1.GeneralContent3';
export const C_NOTICE_TABLE1_GENERAL_CONTENT4 = 'California.Notice.Table1.GeneralContent4';
export const C_NOTICE_TABLE1_GENERAL_CONTENT5 = 'California.Notice.Table1.GeneralContent5';
export const C_NOTICE_TABLE1_GENERAL_CONTENT6 = 'California.Notice.Table1.GeneralContent6';
export const C_NOTICE_TABLE1_GENERAL_CONTENT7 = 'California.Notice.Table1.GeneralContent7';
export const C_NOTICE_TABLE1_GENERAL_CONTENT8 = 'California.Notice.Table1.GeneralContent8';
export const C_NOTICE_TABLE1_GENERAL_CONTENT9 = 'California.Notice.Table1.GeneralContent9';
export const C_NOTICE_TABLE1_GENERAL_CONTENT10 = 'California.Notice.Table1.GeneralContent10';
export const C_NOTICE_TABLE1_GENERAL_CONTENT11 = 'California.Notice.Table1.GeneralContent11';
export const C_NOTICE_TABLE1_GENERAL_CONTENT12 = 'California.Notice.Table1.GeneralContent12';
export const C_NOTICE_TABLE1_GENERAL_CONTENT13 = 'California.Notice.Table1.GeneralContent13';
export const C_NOTICE_TABLE1_GENERAL_CONTENT14 = 'California.Notice.Table1.GeneralContent14';
export const C_NOTICE_TABLE1_GENERAL_CONTENT15 = 'California.Notice.Table1.GeneralContent15';
export const C_NOTICE_TABLE1_GENERAL_CONTENT16 = 'California.Notice.Table1.GeneralContent16';
export const C_NOTICE_TABLE1_GENERAL_CONTENT17 = 'California.Notice.Table1.GeneralContent17';
export const C_NOTICE_TABLE1_GENERAL_CONTENT18 = 'California.Notice.Table1.GeneralContent18';
export const C_NOTICE_TABLE1_GENERAL_CONTENT19 = 'California.Notice.Table1.GeneralContent19';
export const C_NOTICE_TABLE1_GENERAL_CONTENT20 = 'California.Notice.Table1.GeneralContent20';
export const C_NOTICE_TABLE1_GENERAL_CONTENT21 = 'California.Notice.Table1.GeneralContent21';
export const C_NOTICE_SUBTITLE_9 = 'California.Notice.Subtitle.9';
export const C_NOTICE_UNIT9_P1 = 'California.Notice.Unit9.p1';
export const PRIVACY_NOTICE_NOTES_TITLE = 'Privacy.Notice.Notes.Title';

export const ONLINE_ACCESS_TITLE = 'OnlineAccessAndUsageAgreement.Title';
export const ONLINE_ACCESS_INTRO_P1 = 'Online.Access.Intro.P1';
export const ONLINE_ACCESS_INTRO_P2 = 'Online.Access.Intro.P2';
export const ONLINE_ACCESS_INTRO_P3 = 'Online.Access.Intro.P3';
export const ONLINE_ACCESS_INTRO_P4 = 'Online.Access.Intro.P4';
export const ONLINE_ACCESS_INTRO_P5 = 'Online.Access.Intro.P5';
export const ONLINE_ACCESS_INTRO_P6 = 'Online.Access.Intro.P6';
export const ONLINE_ACCESS_INTRO_P7 = 'Online.Access.Intro.P7';
export const ONLINE_ACCESS_INTRO_P8 = 'Online.Access.Intro.P8';
export const ONLINE_ACCESS_INTRO_P9 = 'Online.Access.Intro.P9';
export const ONLINE_ACCESS_INTRO_P10 = 'Online.Access.Intro.P10';

export const ONLINE_ACCESS_UNIT1_TITLE = 'Online.Access.Unit1.Title';
export const ONLINE_ACCESS_UNIT1_P1 = 'Online.Access.Unit1.p1';
export const ONLINE_ACCESS_UNIT1_P2 = 'Online.Access.Unit1.p2';
export const ONLINE_ACCESS_UNIT1_P3 = 'Online.Access.Unit1.p3';

export const ONLINE_ACCESS_UNIT2_TITLE = 'Online.Access.Unit2.Title';
export const ONLINE_ACCESS_UNIT2_P1 = 'Online.Access.Unit2.p1';
export const ONLINE_ACCESS_UNIT2_P2 = 'Online.Access.Unit2.p2';
export const ONLINE_ACCESS_UNIT2_P3 = 'Online.Access.Unit2.p3';
export const ONLINE_ACCESS_UNIT2_P4 = 'Online.Access.Unit2.p4';

export const ONLINE_ACCESS_UNIT3_TITLE = 'Online.Access.Unit3.Title';
export const ONLINE_ACCESS_UNIT3_P1 = 'Online.Access.Unit3.p1';
export const ONLINE_ACCESS_UNIT3_P2 = 'Online.Access.Unit3.p2';
export const ONLINE_ACCESS_UNIT3_P3 = 'Online.Access.Unit3.p3';
export const ONLINE_ACCESS_UNIT3_P4 = 'Online.Access.Unit3.p4';
export const ONLINE_ACCESS_UNIT3_P5 = 'Online.Access.Unit3.p5';
export const ONLINE_ACCESS_UNIT3_P6 = 'Online.Access.Unit3.p6';
export const ONLINE_ACCESS_UNIT3_P7 = 'Online.Access.Unit3.p7';
export const ONLINE_ACCESS_UNIT3_P8 = 'Online.Access.Unit3.p8';
export const ONLINE_ACCESS_UNIT3_P9 = 'Online.Access.Unit3.p9';
export const ONLINE_ACCESS_UNIT3_P10_1 = 'Online.Access.Unit3.p10.1';
export const ONLINE_ACCESS_UNIT3_P10_2 = 'Online.Access.Unit3.p10.2';

export const ONLINE_ACCESS_UNIT4_TITLE = 'Online.Access.Unit4.Title';
export const ONLINE_ACCESS_UNIT4_P1 = 'Online.Access.Unit4.p1';
export const ONLINE_ACCESS_UNIT4_P2 = 'Online.Access.Unit4.p2';
export const ONLINE_ACCESS_UNIT4_P3 = 'Online.Access.Unit4.p3';
export const ONLINE_ACCESS_UNIT4_P4 = 'Online.Access.Unit4.p4';
export const ONLINE_ACCESS_UNIT4_P5 = 'Online.Access.Unit4.p5';
export const ONLINE_ACCESS_UNIT4_P6 = 'Online.Access.Unit4.p6';
export const ONLINE_ACCESS_UNIT4_P7 = 'Online.Access.Unit4.p7';
export const ONLINE_ACCESS_UNIT4_P8 = 'Online.Access.Unit4.p8';
export const ONLINE_ACCESS_UNIT4_P9 = 'Online.Access.Unit4.p9';
export const ONLINE_ACCESS_UNIT4_SUBTITLE1 = 'Online.Access.Unit4.Subtitle1';
export const ONLINE_ACCESS_UNIT4_SUBTITLE2 = 'Online.Access.Unit4.Subtitle2';
export const ONLINE_ACCESS_UNIT4_P10 = 'Online.Access.Unit4.p10';
export const ONLINE_ACCESS_UNIT4_P11 = 'Online.Access.Unit4.p11';
export const ONLINE_ACCESS_UNIT5_TITLE = 'Online.Access.Unit5.Title';
export const ONLINE_ACCESS_UNIT5_P1 = 'Online.Access.Unit5.p1';
export const ONLINE_ACCESS_UNIT5_P2 = 'Online.Access.Unit5.p2';
export const ONLINE_ACCESS_UNIT5_P3 = 'Online.Access.Unit5.p3';
export const ONLINE_ACCESS_UNIT5_SUBTITLE1 = 'Online.Access.Unit5.Subtitle1';
export const ONLINE_ACCESS_UNIT5_SUBTITLE2 = 'Online.Access.Unit5.Subtitle2';
export const ONLINE_ACCESS_UNIT6_TITLE = 'Online.Access.Unit6.Title';
export const ONLINE_ACCESS_UNIT6_P1 = 'Online.Access.Unit6.p1';
export const ONLINE_ACCESS_UNIT6_P2 = 'Online.Access.Unit6.p2';
export const ONLINE_ACCESS_UNIT6_P3 = 'Online.Access.Unit6.p3';
export const ONLINE_ACCESS_UNIT6_P4 = 'Online.Access.Unit6.p4';
export const ONLINE_ACCESS_UNIT6_SUBTITLE1 = 'Online.Access.Unit6.Subtitle1';
export const ONLINE_ACCESS_UNIT6_SUBTITLE2 = 'Online.Access.Unit6.Subtitle2';
export const ONLINE_ACCESS_UNIT7_TITLE = 'Online.Access.Unit7.Title';
export const ONLINE_ACCESS_UNIT7_P1 = 'Online.Access.Unit7.p1';
export const ONLINE_ACCESS_UNIT7_P2 = 'Online.Access.Unit7.p2';
export const ONLINE_ACCESS_UNIT8_TITLE = 'Online.Access.Unit8.Title';
export const ONLINE_ACCESS_UNIT8_P1 = 'Online.Access.Unit8.p1';
export const ONLINE_ACCESS_UNIT8_P2 = 'Online.Access.Unit8.p2';
export const ONLINE_ACCESS_UNIT8_P3 = 'Online.Access.Unit8.p3';
export const ONLINE_ACCESS_UNIT8_P4 = 'Online.Access.Unit8.p4';

export const ONLINE_ACCESS_UNIT9_TITLE = 'Online.Access.Unit9.Title';
export const ONLINE_ACCESS_UNIT9_P1 = 'Online.Access.Unit9.p1';
export const ONLINE_ACCESS_UNIT9_P2 = 'Online.Access.Unit9.p2';
export const ONLINE_ACCESS_UNIT9_SUBTITLE1 = 'Online.Access.Unit9.Subtitle1';
export const ONLINE_ACCESS_UNIT9_PART1_P1 = 'Online.Access.Unit9.Part1.p1';
export const ONLINE_ACCESS_UNIT9_PART1_P2 = 'Online.Access.Unit9.Part1.p2';
export const ONLINE_ACCESS_UNIT9_PART1_P3 = 'Online.Access.Unit9.Part1.p3';
export const ONLINE_ACCESS_UNIT9_PART1_P4 = 'Online.Access.Unit9.Part1.p4';
export const ONLINE_ACCESS_UNIT9_SUBTITLE2 = 'Online.Access.Unit9.Subtitle2';
export const ONLINE_ACCESS_UNIT9_PART2_P1 = 'Online.Access.Unit9.Part2.p1';
export const ONLINE_ACCESS_UNIT9_PART2_P2 = 'Online.Access.Unit9.Part2.p2';
export const ONLINE_ACCESS_UNIT9_PART2_P3 = 'Online.Access.Unit9.Part2.p3';
export const ONLINE_ACCESS_UNIT9_PART2_P4 = 'Online.Access.Unit9.Part2.p4';
export const ONLINE_ACCESS_UNIT9_PART2_P5 = 'Online.Access.Unit9.Part2.p5';
export const ONLINE_ACCESS_UNIT9_SUBTITLE3 = 'Online.Access.Unit9.Subtitle2';
export const ONLINE_ACCESS_UNIT9_PART3_P1 = 'Online.Access.Unit9.Part3.p1';
export const ONLINE_ACCESS_UNIT9_PART3_P2 = 'Online.Access.Unit9.Part3.p2';
export const ONLINE_ACCESS_UNIT9_PART3_P3 = 'Online.Access.Unit9.Part3.p3';
export const ONLINE_ACCESS_UNIT9_PART3_P4 = 'Online.Access.Unit9.Part3.p4';
export const ONLINE_ACCESS_UNIT9_PART3_P5 = 'Online.Access.Unit9.Part3.p5';
export const ONLINE_ACCESS_UNIT10_TITLE1 = 'Online.Access.Unit10.Title1';
export const ONLINE_ACCESS_UNIT10_TITLE2 = 'Online.Access.Unit10.Title2';
export const ONLINE_ACCESS_UNIT10_TITLE3 = 'Online.Access.Unit10.Title3';
export const ONLINE_ACCESS_UNIT10_P1 = 'Online.Access.Unit10.p1';
export const ONLINE_ACCESS_UNIT10_P2 = 'Online.Access.Unit10.p2';
export const ONLINE_ACCESS_UNIT11_TITLE = 'Online.Access.Unit11.Title';
export const ONLINE_ACCESS_UNIT11_P1 = 'Online.Access.Unit11.p1';
export const ONLINE_ACCESS_UNIT11_P2 = 'Online.Access.Unit11.p2';
export const ONLINE_ACCESS_UNIT11_P3 = 'Online.Access.Unit11.p3';
export const ONLINE_ACCESS_UNIT11_P4 = 'Online.Access.Unit11.p4';
export const ONLINE_ACCESS_UNIT11_P5 = 'Online.Access.Unit11.p5';
export const ONLINE_ACCESS_UNIT11_P6 = 'Online.Access.Unit11.p6';
export const ONLINE_ACCESS_UNIT11_TITLE2 = 'Online.Access.Unit11.Title2';
export const ONLINE_ACCESS_UNIT11_P7 = 'Online.Access.Unit11.p7';
export const ONLINE_ACCESS_UNIT11_TITLE3 = 'Online.Access.Unit11.Title3';
export const ONLINE_ACCESS_UNIT11_P8 = 'Online.Access.Unit11.p8';
export const ONLINE_ACCESS_UNIT11_TITLE4 = 'Online.Access.Unit11.Title4';
export const ONLINE_ACCESS_UNIT11_P9 = 'Online.Access.Unit11.p9';
export const ONLINE_ACCESS_UNIT11_TITLE5 = 'Online.Access.Unit11.Title5';
export const ONLINE_ACCESS_UNIT11_P10 = 'Online.Access.Unit11.p10';
export const ONLINE_ACCESS_UNIT11_TITLE6 = 'Online.Access.Unit11.Title6';
export const ONLINE_ACCESS_UNIT11_P11 = 'Online.Access.Unit11.p11';
export const ACKNOWLEDGE_CONSENT = 'Online.Access.Acknowledge.Consent';
export const CONSENT_P1 = 'Online.Access.Consent.p1';

export const ACH_PAYMENT_AUTHORIZATION_TITLE = 'Ach.Payment.Authorization.Title';
export const ACH_PAYMENT_AUTHORIZATION_TEXT1_AUDI = 'ACH.Payment.Authorization.Text1';
export const ACH_PAYMENT_AUTHORIZATION_TEXT2_AUDI = 'ACH.Payment.Authorization.Text2';
export const ACH_PAYMENT_AUTHORIZATION_TEXT3_AUDI = 'ACH.Payment.Authorization.Text3';
export const ACH_PAYMENT_AUTHORIZATION_TEXT1_VW = 'VW.ACH.Payment.Authorization.Text1';
export const ACH_PAYMENT_AUTHORIZATION_TEXT2_VW = 'VW.ACH.Payment.Authorization.Text2';
export const ACH_PAYMENT_AUTHORIZATION_TEXT3_VW = 'VW.ACH.Payment.Authorization.Text3';
export const ACH_PAYMENT_AUTHORIZATION_TEXT4_VW = 'VW.ACH.Payment.Authorization.Text4';
export const AUTHORIZATION_AGREEMENT_TITLE = 'Authorization.Agreement.Title';
export const AUTOMATIC_PAYMENT_PLAN_TITLE = 'AutoPay.Plan.Title';
export const AUTOMATIC_PAYMENT_PLAN_TITLE_2 = 'AutoPay.Plan.Title.2';
export const OPTIONAL_ENROLLMENT_AUTOPAY_TITLE = 'Optional.Enrollment.Autopay.Title';
export const ONE_TIME_PAYMENTS_TITLE = 'One.Time.Payments.Title';
export const SECURE_FUNDS_TITLE = 'Secure.Funds.Title';
export const MONTHLY_PAYMENTS_TITLE = 'Monthly.Payments.Title';
export const CANCELING_AUTHORIZATION_TITLE = 'Canceling.Authorization.Title';
export const EFUNDS_TRANSFER_ACT_TITLE = 'EFunds.Transfer.Act.Title';
export const AUTHORIZATION_AGREEMENT_PAYMENT_PLAN = 'Authorization.Agreement.Automatic.Payment.Plan.Message';
export const VW_AUTHORIZATION_AGREEMENT_PAYMENT_PLAN = 'VW_Authorization.Agreement.Automatic.Payment.Plan.Message';
export const AUTHORIZATION_AGREEMENT_OPTIONAL_ENROLLMENT = 'Authorization.Agreement.OptionalEnrollment.Message';
export const VW_AUTHORIZATION_AGREEMENT_OPTIONAL_ENROLLMENT = 'VW_Authorization.Agreement.OptionalEnrollment.Message';
export const AUTHORIZATION_AGREEMENT_SECURE_FUNDS = 'Authorization.Agreement.Secure.Funds.Message';
export const AUTHORIZATION_AGREEMENT_ONE_TIME = 'Authorization.Agreement.OneTime.Message';
export const VW_AUTHORIZATION_AGREEMENT_ONE_TIME = 'VW_Authorization.Agreement.OneTime.Message';
export const AUTHORIZATION_AGREEMENT_MONTHLY_PAYMENT = 'Authorization.Agreement.Monthly.Payments.Message';
export const VW_AUTHORIZATION_AGREEMENT_MONTHLY_PAYMENT = 'VW_Authorization.Agreement.Monthly.Payments.Message';
export const AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_1 =
  'Authorization.Agreement.Cancelling.Authorization.Message.1';
export const AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATIN_5DAYS =
  'Authorization.Agreement.Cancelling.Authorization.Message.5days';
export const AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_2 =
  'Authorization.Agreement.Cancelling.Authorization.Message.2';
export const AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_3 =
  'Authorization.Agreement.Cancelling.Authorization.Message.3';
export const CANCELLING_AUTHORIZATION_CONTACT_INFO = 'Cancelling.Authorization.Contact.Info';
export const VW_CANCELLING_AUTHORIZATION_CONTACT_INFO = 'VW_Cancelling.Authorization.Contact.Info';
export const CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT1 = 'Cancelling.Authorization.Contact.Info.Text1';
export const CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT2 = 'Cancelling.Authorization.Contact.Info.Text2';
export const CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT3 = 'Cancelling.Authorization.Contact.Info.Text3';
export const ELECTRONICS_FUNDS_MESSAGE = 'Electronic.Funds.Message';
export const MYAUDI_LOGIN_TITLE = 'MyAudi.Login.Title';
export const FOOTER_MESSAGE = 'Footer.Message';
export const FOOTER_MESSAGE_CANADA = 'Footer.Message.Canada';
export const AUDI_LOGO_TEXT = 'Audi.Logo.Text';
export const AUDI_FINANCIAL_SERVICES = 'Audi.Logo.Text2';
export const TANDC = 'tnc';
export const ONLINE_ACCESS_LIMIT_TITLE = 'OnlineAccessAndUsageAgreement.Limit.Title';

export const SMS_TERMSOFSERVICE_TITLE = 'SMS.TermsOfService.Title';
export const SMS_TERMSOFSERVICE_SUBTITLE = 'SMS.TermsOfService.Subtitle';
export const SMS_TERMSOFSERVICE_SHORTCODE_HEADER_VWC = 'SMS.TermsOfService.ShortCode.Header.VWC';
export const SMS_TERMSOFSERVICE_SHORTCODE_CODE_VWC = 'SMS.TermsOfService.ShortCode.Code.VWC';
export const SMS_TERMSOFSERVICE_SHORTCODE_HEADER_AFS = 'SMS.TermsOfService.ShortCode.Header.AFS';
export const SMS_TERMSOFSERVICE_SHORTCODE_CODE_AFS = 'SMS.TermsOfService.ShortCode.Code.AFS';
export const SMS_TERMSOFSERVICE_UNIT1_P1 = 'SMS.TermsOfService.Unit1.p1';
export const SMS_TERMSOFSERVICE_UNIT1_P2 = 'SMS.TermsOfService.Unit1.p2';
export const SMS_TERMSOFSERVICE_UNIT1_P2_AFS_LINK = 'SMS.TermsOfService.Unit1.p2.AFS.Link';
export const SMS_TERMSOFSERVICE_UNIT1_P2_VWC_LINK = 'SMS.TermsOfService.Unit1.p2.VWC.Link';
export const SMS_TERMSOFSERVICE_UNIT1_P3 = 'SMS.TermsOfService.Unit1.p3';
export const SMS_TERMSOFSERVICE_UNIT1_P4 = 'SMS.TermsOfService.Unit1.p4';
export const SMS_TERMSOFSERVICE_UNIT1_P5 = 'SMS.TermsOfService.Unit1.p5';
export const SMS_TERMSOFSERVICE_UNIT1_P6 = 'SMS.TermsOfService.Unit1.p6';
export const SMS_TERMSOFSERVICE_UNIT1_P7_AFS = 'SMS.TermsOfService.Unit1.p7.AFS';
export const SMS_TERMSOFSERVICE_UNIT1_P7_VWC = 'SMS.TermsOfService.Unit1.p7.VWC';
export const SMS_TERMSOFSERVICE_UNIT1_P8 = 'SMS.TermsOfService.Unit1.p8';
export const SMS_TERMSOFSERVICE_UNIT1_P9 = 'SMS.TermsOfService.Unit1.p9';
export const SMS_TERMSOFSERVICE_UNIT1_P10 = 'SMS.TermsOfService.Unit1.p10';
export const SMS_TERMSOFSERVICE_UNIT1_P11 = 'SMS.TermsOfService.Unit1.p11';
export const SMS_TERMSOFSERVICE_UNIT1_P12 = 'SMS.TermsOfService.Unit1.p12';
export const SMS_TERMSOFSERVICE_UNIT1_P13 = 'SMS.TermsOfService.Unit1.p13';
export const SMS_TERMSOFSERVICE_UNIT2_P1 = 'SMS.TermsOfService.Unit2.p1';
export const SMS_TERMSOFSERVICE_UNIT2_P2 = 'SMS.TermsOfService.Unit2.p2';
export const SMS_TERMSOFSERVICE_UNIT2_P3 = 'SMS.TermsOfService.Unit2.p3';
export const SMS_TERMSOFSERVICE_UNIT2_P4 = 'SMS.TermsOfService.Unit2.p4';
export const SMS_TERMSOFSERVICE_UNIT2_P5 = 'SMS.TermsOfService.Unit2.p5';
export const SMS_TERMSOFSERVICE_UNIT2_P6 = 'SMS.TermsOfService.Unit2.p6';
export const SMS_TERMSOFSERVICE_UNIT2_P7 = 'SMS.TermsOfService.Unit2.p7';
export const SMS_TERMSOFSERVICE_UNIT3_P1 = 'SMS.TermsOfService.Unit3.p1';
export const SMS_TERMSOFSERVICE_UNIT3_P2 = 'SMS.TermsOfService.Unit3.p2';
export const SMS_TERMSOFSERVICE_UNIT3_P3 = 'SMS.TermsOfService.Unit3.p3';
export const SMS_TERMSOFSERVICE_UNIT3_P4 = 'SMS.TermsOfService.Unit3.p4';
export const SMS_TERMSOFSERVICE_UNIT3_P5 = 'SMS.TermsOfService.Unit3.p5';
export const SMS_TERMSOFSERVICE_UNIT3_P6 = 'SMS.TermsOfService.Unit3.p6';
export const SMS_TERMSOFSERVICE_UNIT3_P7 = 'SMS.TermsOfService.Unit3.p7';
export const SMS_TERMSOFSERVICE_UNIT4_P1 = 'SMS.TermsOfService.Unit4.p1';
export const SMS_TERMSOFSERVICE_UNIT4_P2 = 'SMS.TermsOfService.Unit4.p2';
export const SMS_TERMSOFSERVICE_UNIT4_P3 = 'SMS.TermsOfService.Unit4.p3';
export const SMS_TERMSOFSERVICE_UNIT5_P1 = 'SMS.TermsOfService.Unit5.p1';
export const SMS_TERMSOFSERVICE_UNIT6_P1 = 'SMS.TermsOfService.Unit6.p1';
export const SMS_TERMSOFSERVICE_UNIT6_P2 = 'SMS.TermsOfService.Unit6.p2';
export const SMS_TERMSOFSERVICE_UNIT6_P2_AFS = 'SMS.TermsOfService.Unit6.p2.AFS';
export const SMS_TERMSOFSERVICE_UNIT6_P2_VWC = 'SMS.TermsOfService.Unit6.p2.VWC';
export const SMS_TERMSOFSERVICE_UNIT6_P3 = 'SMS.TermsOfService.Unit6.p3';
export const SMS_TERMSOFSERVICE_UNIT6_P3_AFS = 'SMS.TermsOfService.Unit6.p3.AFS';
export const SMS_TERMSOFSERVICE_UNIT6_P3_VWC = 'SMS.TermsOfService.Unit6.p3.VWC';
export const SMS_TERMSOFSERVICE_UNIT7_P1 = 'SMS.TermsOfService.Unit7.p1';
export const SMS_TERMSOFSERVICE_UNIT7_P2 = 'SMS.TermsOfService.Unit7.p2';
export const SMS_TERMSOFSERVICE_UNIT7_P3 = 'SMS.TermsOfService.Unit7.p3';
export const SMS_TERMSOFSERVICE_UNIT8_P1 = 'SMS.TermsOfService.Unit8.p1';

// CANADA USAGE AGREEMENT

export const TERMS_AND_POLICIES_CANADA = 'TermsAndPoliciesTitle.Canada';

export const PRIVACY_PORTAL_LINK_CANADA = 'PrivacyPortal.Link.Canada';
export const PRIVACY_PORTAL_LINK_CANADA_AUDI = 'PrivacyPortal.Link.Canada.Audi';
export const PRIVACY_PORTAL_LINK_CANADA_VW = 'PrivacyPortal.Link.Canada.VW';

export const USAGE_AGREEMENT_TITLE = 'Usage.Agreement.Title';
export const USAGE_AGREEMENT_SUBTITLE = 'Usage.Agreement.Subtitle';
export const USAGE_AGREEMENT_INTRO_TITLE = 'Usage.Agreement.Intro.Title';
export const USAGE_AGREEMENT_INTRO_P1 = 'Usage.Agreement.Intro.p1';
export const USAGE_AGREEMENT_INTRO_PARAGRAPH1 = 'Usage.Agreement.Intro.Paragraph1';
export const USAGE_AGREEMENT_INTRO_PARAGRAPH3_1 = 'Usage.Agreement.Intro.Paragraph3.1';
export const USAGE_AGREEMENT_INTRO_PARAGRAPH3_LINK_AUDI = 'Usage.Agreement.Intro.Paragraph3.Link.Audi';
export const USAGE_AGREEMENT_INTRO_PARAGRAPH3_LINK_VW = 'Usage.Agreement.Intro.Paragraph3.Link.VW';
export const USAGE_AGREEMENT_INTRO_PARAGRAPH3_2 = 'Usage.Agreement.Intro.Paragraph3.2';
export const USAGE_AGREEMENT_INTRO_PARAGRAPH4 = 'Usage.Agreement.Intro.Paragraph4';
export const USAGE_AGREEMENT_SUBTITLE_1 = 'Usage.Agreement.Subtitle.1';
export const USAGE_AGREEMENT_UNIT1_PARAGRAPH1 = 'Usage.Agreement.Unit1.Paragraph1';
export const USAGE_AGREEMENT_UNIT1_PARAGRAPH2 = 'Usage.Agreement.Unit1.Paragraph2';
export const USAGE_AGREEMENT_UNIT1_PARAGRAPH3 = 'Usage.Agreement.Unit1.Paragraph3';
export const USAGE_AGREEMENT_SUBTITLE_2 = 'Usage.Agreement.Subtitle.2';
export const USAGE_AGREEMENT_UNIT2_PARAGRAPH1 = 'Usage.Agreement.Unit2.Paragraph1';
export const USAGE_AGREEMENT_SUBTITLE_3 = 'Usage.Agreement.Subtitle.3';
export const USAGE_AGREEMENT_UNIT3_PARAGRAPH1 = 'Usage.Agreement.Unit3.Paragraph1';
export const USAGE_AGREEMENT_UNIT3_PARAGRAPH2 = 'Usage.Agreement.Unit3.Paragraph2';
export const USAGE_AGREEMENT_SUBTITLE_4 = 'Usage.Agreement.Subtitle.4';
export const USAGE_AGREEMENT_UNIT4_PARAGRAPH1 = 'Usage.Agreement.Unit4.Paragraph1';
export const USAGE_AGREEMENT_SUBTITLE_5 = 'Usage.Agreement.Subtitle.5';
export const USAGE_AGREEMENT_UNIT5_PARAGRAPH1 = 'Usage.Agreement.Unit5.Paragraph1';
export const USAGE_AGREEMENT_SUBTITLE_6 = 'Usage.Agreement.Subtitle.6';
export const USAGE_AGREEMENT_UNIT6_PARAGRAPH1 = 'Usage.Agreement.Unit6.Paragraph1';

//  CANADA Privacy Policy
export const PRIVACY_POLICY_TITLE = 'Privacy.Policy.Title';
export const PRIVACY_POLICY_TITLE_VW = 'Privacy.Policy.Title.VW';
export const PRIVACY_POLICY_TITLE_AUDI = 'Privacy.Policy.Title.Audi';
export const PRIVACY_POLICY_INTRO_PARAGRAPH1 = 'Privacy.Policy.Intro.Paragraph1';
export const PRIVACY_POLICY_INTRO_PARAGRAPH3_1 = 'Privacy.Policy.Intro.Paragraph3.1';
export const PRIVACY_POLICY_INTRO_PARAGRAPH3_AUDI = 'Privacy.Policy.Intro.Paragraph3.Link.Audi';
export const PRIVACY_POLICY_INTRO_PARAGRAPH3_VW = 'Privacy.Policy.Intro.Paragraph3.Link.VW';
export const PRIVACY_POLICY_INTRO_PARAGRAPH3_2 = 'Privacy.Policy.Intro.Paragraph3.2';
export const PRIVACY_POLICY_SUBTITLE_1 = 'Privacy.Policy.Subtitle.1';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH1 = 'Privacy.Policy.Unit1.Paragraph1';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_1 = 'Privacy.Policy.Unit1.Paragraph2.List1.1';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_2 = 'Privacy.Policy.Unit1.Paragraph2.List1.2';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_3 = 'Privacy.Policy.Unit1.Paragraph2.List1.3';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_4 = 'Privacy.Policy.Unit1.Paragraph2.List1.4';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_5 = 'Privacy.Policy.Unit1.Paragraph2.List1.5';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_6 = 'Privacy.Policy.Unit1.Paragraph2.List1.6';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_7 = 'Privacy.Policy.Unit1.Paragraph2.List1.7';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_8 = 'Privacy.Policy.Unit1.Paragraph2.List1.8';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_9 = 'Privacy.Policy.Unit1.Paragraph2.List1.9';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_10 = 'Privacy.Policy.Unit1.Paragraph2.List1.10';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_11 = 'Privacy.Policy.Unit1.Paragraph2.List1.11';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_12 = 'Privacy.Policy.Unit1.Paragraph2.List1.12';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH3_1 = 'Privacy.Policy.Unit1.Paragraph3.1';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH3_2 = 'Privacy.Policy.Unit1.Paragraph3.2';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH3_3 = 'Privacy.Policy.Unit1.Paragraph3.3';
export const PRIVACY_POLICY_UNIT1_PARAGRAPH4 = 'Privacy.Policy.Unit1.Paragraph4';
export const PRIVACY_POLICY_SUBTITLE_2 = 'Privacy.Policy.Subtitle.2';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH1 = 'Privacy.Policy.Unit2.Paragraph1';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_1 = 'Privacy.Policy.Unit2.Paragraph2.1';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_1 = 'Privacy.Policy.Unit2.Paragraph2.List1.1';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_2 = 'Privacy.Policy.Unit2.Paragraph2.List1.2';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_3 = 'Privacy.Policy.Unit2.Paragraph2.List1.3';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_4 = 'Privacy.Policy.Unit2.Paragraph2.List1.4';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_5 = 'Privacy.Policy.Unit2.Paragraph2.List1.5';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_6 = 'Privacy.Policy.Unit2.Paragraph2.List1.6';
export const PRIVACY_POLICY_UNIT2_PARAGRAPH4 = 'Privacy.Policy.Unit2.Paragraph4';
export const PRIVACY_POLICY_SUBTITLE_3 = 'Privacy.Policy.Subtitle.3';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH1 = 'Privacy.Policy.Unit3.Paragraph1';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_1 = 'Privacy.Policy.Unit3.Paragraph2.List1.1';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_2 = 'Privacy.Policy.Unit3.Paragraph2.List1.2';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_3 = 'Privacy.Policy.Unit3.Paragraph2.List1.3';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_4 = 'Privacy.Policy.Unit3.Paragraph2.List1.4';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_5 = 'Privacy.Policy.Unit3.Paragraph2.List1.5';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_6 = 'Privacy.Policy.Unit3.Paragraph2.List1.6';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_7 = 'Privacy.Policy.Unit3.Paragraph2.List1.7';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_8 = 'Privacy.Policy.Unit3.Paragraph2.List1.8';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_9 = 'Privacy.Policy.Unit3.Paragraph2.List1.9';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_10 = 'Privacy.Policy.Unit3.Paragraph2.List1.10';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_11 = 'Privacy.Policy.Unit3.Paragraph2.List1.11';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_12 = 'Privacy.Policy.Unit3.Paragraph2.List1.12';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_13 = 'Privacy.Policy.Unit3.Paragraph2.List1.13';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_14 = 'Privacy.Policy.Unit3.Paragraph2.List1.14';
export const PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_15 = 'Privacy.Policy.Unit3.Paragraph2.List1.15';
export const PRIVACY_POLICY_SUBTITLE_4 = 'Privacy.Policy.Subtitle.4';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH1 = 'Privacy.Policy.Unit4.Paragraph1';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_1 = 'Privacy.Policy.Unit4.Paragraph2.List1.1';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_2 = 'Privacy.Policy.Unit4.Paragraph2.List1.2';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_3 = 'Privacy.Policy.Unit4.Paragraph2.List1.3';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_4 = 'Privacy.Policy.Unit4.Paragraph2.List1.4';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_5 = 'Privacy.Policy.Unit4.Paragraph2.List1.5';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_6 = 'Privacy.Policy.Unit4.Paragraph2.List1.6';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_7 = 'Privacy.Policy.Unit4.Paragraph2.List1.7';
export const PRIVACY_POLICY_UNIT4_PARAGRAPH3 = 'Privacy.Policy.Unit4.Paragraph3';
export const PRIVACY_POLICY_SUBTITLE_5 = 'Privacy.Policy.Subtitle.5';
export const PRIVACY_POLICY_UNIT5_PARAGRAPH1 = 'Privacy.Policy.Unit5.Paragraph1';
export const PRIVACY_POLICY_UNIT5_PARAGRAPH2 = 'Privacy.Policy.Unit5.Paragraph2';
export const PRIVACY_POLICY_UNIT5_PARAGRAPH3 = 'Privacy.Policy.Unit5.Paragraph3';
export const PRIVACY_POLICY_SUBTITLE_6 = 'Privacy.Policy.Subtitle.6';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH1 = 'Privacy.Policy.Unit6.Paragraph1';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH2_LIST1_1 = 'Privacy.Policy.Unit6.Paragraph2.List1.1';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH2_LIST1_2 = 'Privacy.Policy.Unit6.Paragraph2.List1.2';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH3 = 'Privacy.Policy.Unit6.Paragraph3';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH4_LIST1_1 = 'Privacy.Policy.Unit6.Paragraph4.List1.1';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH4_LIST1_2 = 'Privacy.Policy.Unit6.Paragraph4.List1.2';
export const PRIVACY_POLICY_UNIT6_PARAGRAPH5 = 'Privacy.Policy.Unit6.Paragraph5';
export const PRIVACY_POLICY_SUBTITLE_7 = 'Privacy.Policy.Subtitle.7';
export const PRIVACY_POLICY_UNIT7_PARAGRAPH1 = 'Privacy.Policy.Unit7.Paragraph1';
export const PRIVACY_POLICY_UNIT7_PARAGRAPH2 = 'Privacy.Policy.Unit7.Paragraph2';
export const PRIVACY_POLICY_UNIT7_PARAGRAPH3 = 'Privacy.Policy.Unit7.Paragraph3';
export const PRIVACY_POLICY_SUBTITLE_8 = 'Privacy.Policy.Subtitle.8';
export const PRIVACY_POLICY_UNIT8_PARAGRAPH1 = 'Privacy.Policy.Unit8.Paragraph1';
export const PRIVACY_POLICY_UNIT8_PARAGRAPH2 = 'Privacy.Policy.Unit8.Paragraph2';
export const PRIVACY_POLICY_UNIT8_PARAGRAPH3 = 'Privacy.Policy.Unit8.Paragraph3';
export const PRIVACY_POLICY_UNIT8_PARAGRAPH4 = 'Privacy.Policy.Unit8.Paragraph4';
export const PRIVACY_POLICY_SUBTITLE_9 = 'Privacy.Policy.Subtitle.9';
export const PRIVACY_POLICY_UNIT9_PARAGRAPH1 = 'Privacy.Policy.Unit9.Paragraph1';
export const PRIVACY_POLICY_SUBTITLE_10 = 'Privacy.Policy.Subtitle.10';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH1 = 'Privacy.Policy.Unit10.Paragraph1';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH2 = 'Privacy.Policy.Unit10.Paragraph2';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH3 = 'Privacy.Policy.Unit10.Paragraph3';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH4 = 'Privacy.Policy.Unit10.Paragraph4';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH5 = 'Privacy.Policy.Unit10.Paragraph5';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH6 = 'Privacy.Policy.Unit10.Paragraph6';
export const PRIVACY_POLICY_UNIT10_PARAGRAPH7 = 'Privacy.Policy.Unit10.Paragraph7';
export const PRIVACY_POLICY_SUBTITLE_11 = 'Privacy.Policy.Subtitle.11';
export const PRIVACY_POLICY_UNIT11_PARAGRAPH1 = 'Privacy.Policy.Unit11.Paragraph1';
export const PRIVACY_POLICY_SUBTITLE_12 = 'Privacy.Policy.Subtitle.12';
export const PRIVACY_POLICY_UNIT12_PARAGRAPH1 = 'Privacy.Policy.Unit12.Paragraph1';
export const PRIVACY_POLICY_SUBTITLE_13 = 'Privacy.Policy.Subtitle.13';
export const PRIVACY_POLICY_UNIT13_PARAGRAPH1 = 'Privacy.Policy.Unit13.Paragraph1';
export const PRIVACY_POLICY_UNIT13_PARAGRAPH2_1 = 'Privacy.Policy.Unit13.Paragraph2.1';
export const PRIVACY_POLICY_UNIT13_PARAGRAPH2_2 = 'Privacy.Policy.Unit13.Paragraph2.2';
export const PRIVACY_POLICY_UNIT13_PARAGRAPH3 = 'Privacy.Policy.Unit13.Paragraph3';

// B2B Policy
export const B2B_POLICY_UNIT1_PARAGRAPH1 = 'B2B.Policy.Unit1.Paragraph1';
export const B2B_POLICY_UNIT1_PARAGRAPH2 = 'B2B.Policy.Unit1.Paragraph2';
export const B2B_POLICY_UNIT1_PARAGRAPH3_1 = 'B2B.Policy.Unit1.Paragraph3.1';
export const B2B_POLICY_UNIT1_PARAGRAPH3 = 'B2B.Policy.Unit1.Paragraph3';
export const B2B_POLICY_UNIT2_TITLE = 'B2B.Policy.Unit2.Title';
export const B2B_POLICY_UNIT2_PARAGRAPH1 = 'B2B.Policy.Unit2.Paragraph1';
export const B2B_POLICY_UNIT2_P1_TITLE = 'B2B.Policy.Unit2.p1.Title';
export const B2B_POLICY_UNIT2_P1_TEXT = 'B2B.Policy.Unit2.p1.Text';
export const B2B_POLICY_UNIT2_P2_TITLE = 'B2B.Policy.Unit2.p2.Title';
export const B2B_POLICY_UNIT2_P2_TEXT = 'B2B.Policy.Unit2.p2.Text';
export const B2B_POLICY_UNIT2_P3_TITLE = 'B2B.Policy.Unit2.p3.Title';
export const B2B_POLICY_UNIT2_P3_TEXT = 'B2B.Policy.Unit2.p3.Text';
export const B2B_POLICY_UNIT2_PARAGRAPH2 = 'B2B.Policy.Unit2.Paragraph2';
export const B2B_POLICY_UNIT3_TITLE = 'B2B.Policy.Unit3.Title';
export const B2B_POLICY_UNIT3_PARAGRAPH1 = 'B2B.Policy.Unit3.Paragraph1';
export const B2B_POLICY_UNIT3_P1_TITLE = 'B2B.Policy.Unit3.p1.Title';
export const B2B_POLICY_UNIT3_P1_TEXT = 'B2B.Policy.Unit3.p1.Text';
export const B2B_POLICY_UNIT3_P2_TITLE = 'B2B.Policy.Unit3.p2.Title';
export const B2B_POLICY_UNIT3_P2_TEXT = 'B2B.Policy.Unit3.p2.Text';
export const B2B_POLICY_UNIT3_P3_TITLE = 'B2B.Policy.Unit3.p3.Title';
export const B2B_POLICY_UNIT3_P3_TEXT = 'B2B.Policy.Unit3.p3.Text';
export const B2B_POLICY_UNIT3_P4_TITLE = 'B2B.Policy.Unit3.p4.Title';
export const B2B_POLICY_UNIT3_P4_TEXT = 'B2B.Policy.Unit3.p4.Text';
export const B2B_POLICY_UNIT4_TITLE = 'B2B.Policy.Unit4.Title';
export const B2B_POLICY_UNIT4_PARAGRAPH1 = 'B2B.Policy.Unit4.Paragraph1';
export const B2B_POLICY_UNIT4_P1_TITLE = 'B2B.Policy.Unit4.p1.Title';
export const B2B_POLICY_UNIT4_P1_TEXT = 'B2B.Policy.Unit4.p1.Text';
export const B2B_POLICY_UNIT4_P2_TITLE = 'B2B.Policy.Unit4.p2.Title';
export const B2B_POLICY_UNIT4_P2_TEXT = 'B2B.Policy.Unit4.p2.Text';
export const B2B_POLICY_UNIT4_P3_TITLE = 'B2B.Policy.Unit4.p3.Title';
export const B2B_POLICY_UNIT4_P3_TEXT = 'B2B.Policy.Unit4.p3.Text';
export const B2B_POLICY_UNIT4_P4_TITLE = 'B2B.Policy.Unit4.p4.Title';
export const B2B_POLICY_UNIT4_P4_TEXT = 'B2B.Policy.Unit4.p4.Text';
export const B2B_POLICY_UNIT4_P5_TITLE = 'B2B.Policy.Unit4.p5.Title';
export const B2B_POLICY_UNIT4_P5_TEXT = 'B2B.Policy.Unit4.p5.Text';
export const B2B_POLICY_UNIT4_P6_TITLE = 'B2B.Policy.Unit4.p6.Title';
export const B2B_POLICY_UNIT4_P6_TEXT = 'B2B.Policy.Unit4.p6.Text';
export const B2B_POLICY_UNIT4_PARAGRAPH2 = 'B2B.Policy.Unit4.Paragraph2';
export const B2B_POLICY_UNIT5_TITLE = 'B2B.Policy.Unit5.Title';
export const B2B_POLICY_UNIT5_PARAGRAPH1 = 'B2B.Policy.Unit5.Paragraph1';
export const B2B_POLICY_UNIT5_P1_TITLE = 'B2B.Policy.Unit5.p1.Title';
export const B2B_POLICY_UNIT5_P1_TEXT = 'B2B.Policy.Unit5.p1.Text';
export const B2B_POLICY_UNIT5_P2_TITLE = 'B2B.Policy.Unit5.p2.Title';
export const B2B_POLICY_UNIT5_P2_TEXT = 'B2B.Policy.Unit5.p2.Text';
export const B2B_POLICY_UNIT5_P3_TITLE = 'B2B.Policy.Unit5.p3.Title';
export const B2B_POLICY_UNIT5_P3_TEXT = 'B2B.Policy.Unit5.p3.Text';
export const B2B_POLICY_UNIT5_P4_TITLE = 'B2B.Policy.Unit5.p4.Title';
export const B2B_POLICY_UNIT5_P4_TEXT = 'B2B.Policy.Unit5.p4.Text';
export const B2B_POLICY_UNIT5_P5_TITLE = 'B2B.Policy.Unit5.p5.Title';
export const B2B_POLICY_UNIT5_P5_TEXT = 'B2B.Policy.Unit5.p5.Text';
export const B2B_POLICY_UNIT5_P6_TITLE = 'B2B.Policy.Unit5.p6.Title';
export const B2B_POLICY_UNIT5_P6_TEXT = 'B2B.Policy.Unit5.p6.Text';
export const B2B_POLICY_UNIT5_P7_TITLE = 'B2B.Policy.Unit5.p7.Title';
export const B2B_POLICY_UNIT5_P8_TITLE = 'B2B.Policy.Unit5.p8.Title';
export const B2B_POLICY_UNIT6_TITLE = 'B2B.Policy.Unit6.Title';
export const B2B_POLICY_UNIT6_PARAGRAPH1 = 'B2B.Policy.Unit6.Paragraph1';
export const B2B_POLICY_UNIT6_PARAGRAPH2 = 'B2B.Policy.Unit6.Paragraph2';
export const B2B_POLICY_UNIT6_P1_TITLE = 'B2B.Policy.Unit6.p1.Title';
export const B2B_POLICY_UNIT6_P2_TITLE = 'B2B.Policy.Unit6.p2.Title';
export const B2B_POLICY_UNIT6_P3_TITLE = 'B2B.Policy.Unit6.p3.Title';
export const B2B_POLICY_UNIT6_P4_TITLE = 'B2B.Policy.Unit6.p4.Title';
export const B2B_POLICY_UNIT6_P5_TITLE = 'B2B.Policy.Unit6.p5.Title';
export const B2B_POLICY_UNIT6_P6_TITLE = 'B2B.Policy.Unit6.p6.Title';
export const B2B_POLICY_UNIT6_P7_TITLE = 'B2B.Policy.Unit6.p7.Title';
export const B2B_POLICY_UNIT6_P8_TITLE = 'B2B.Policy.Unit6.p8.Title';
export const B2B_POLICY_UNIT6_P9_TITLE = 'B2B.Policy.Unit6.p9.Title';
export const B2B_POLICY_UNIT6_P10_TITLE = 'B2B.Policy.Unit6.p10.Title';
export const B2B_POLICY_UNIT6_P11_TITLE = 'B2B.Policy.Unit6.p11.Title';
export const B2B_POLICY_UNIT6_1_TITLE = 'B2B.Policy.Unit6.1.Title';
export const B2B_POLICY_UNIT6_1_PARAGRAPH1 = 'B2B.Policy.Unit6.1.Paragraph1';
export const B2B_POLICY_UNIT6_1_PARAGRAPH2 = 'B2B.Policy.Unit6.1.Paragraph2';
export const B2B_POLICY_UNIT7_TITLE = 'B2B.Policy.Unit7.Title';
export const B2B_POLICY_UNIT7_PARAGRAPH1 = 'B2B.Policy.Unit7.Paragraph1';
export const B2B_POLICY_UNIT7_PARAGRAPH2 = 'B2B.Policy.Unit7.Paragraph2';
export const B2B_POLICY_UNIT8_TITLE = 'B2B.Policy.Unit8.Title';
export const B2B_POLICY_UNIT8_PARAGRAPH1 = 'B2B.Policy.Unit8.Paragraph1';
export const B2B_POLICY_UNIT9_TITLE = 'B2B.Policy.Unit9.Title';
export const B2B_POLICY_UNIT9_PARAGRAPH1 = 'B2B.Policy.Unit9.Paragraph1';
export const B2B_POLICY_UNIT10_TITLE = 'B2B.Policy.Unit10.Title';
export const B2B_POLICY_UNIT10_PARAGRAPH1 = 'B2B.Policy.Unit10.Paragraph1';
export const B2B_POLICY_UNIT11_TITLE = 'B2B.Policy.Unit11.Title';
export const B2B_POLICY_UNIT11_PARAGRAPH1 = 'B2B.Policy.Unit11.Paragraph1';
export const B2B_POLICY_UNIT12_TITLE = 'B2B.Policy.Unit12.Title';
export const B2B_POLICY_UNIT12_1_TITLE = 'B2B.Policy.Unit12.1.Title';
export const B2B_POLICY_UNIT12_PARAGRAPH1 = 'B2B.Policy.Unit12.Paragraph1';
export const B2B_POLICY_UNIT12_P1_TITLE = 'B2B.Policy.Unit12.p1.Title';
export const B2B_POLICY_UNIT12_P1_TEXT = 'B2B.Policy.Unit12.p1.Text';
export const B2B_POLICY_UNIT12_P2_TITLE = 'B2B.Policy.Unit12.p2.Title';
export const B2B_POLICY_UNIT12_P2_TEXT = 'B2B.Policy.Unit12.p2.Text';
export const B2B_POLICY_UNIT12_P3_TITLE = 'B2B.Policy.Unit12.p3.Title';
export const B2B_POLICY_UNIT12_P3_TEXT = 'B2B.Policy.Unit12.p3.Text';
export const B2B_POLICY_UNIT12_PARAGRAPH2_1 = 'B2B.Policy.Unit12.Paragraph2.1';
export const B2B_POLICY_UNIT12_PARAGRAPH2_2 = 'B2B.Policy.Unit12.Paragraph2.2';
export const B2B_POLICY_UNIT12_PARAGRAPH3_1 = 'B2B.Policy.Unit12.Paragraph3.1';
export const B2B_POLICY_UNIT12_PARAGRAPH3 = 'B2B.Policy.Unit12.Paragraph3';
export const B2B_POLICY_UNIT12_PARAGRAPH4_1 = 'B2B.Policy.Unit12.Paragraph4.1';
export const B2B_POLICY_UNIT12_PARAGRAPH4 = 'B2B.Policy.Unit12.Paragraph4';
export const B2B_POLICY_UNIT12_PARAGRAPH5_1 = 'B2B.Policy.Unit12.Paragraph5.1';
export const B2B_POLICY_UNIT12_PARAGRAPH5 = 'B2B.Policy.Unit12.Paragraph5';
export const B2B_POLICY_UNIT12_2_TITLE = 'B2B.Policy.Unit12.2.Title';
export const B2B_POLICY_UNIT12_PARAGRAPH6 = 'B2B.Policy.Unit12.Paragraph6';
export const B2B_POLICY_UNIT13_TITLE = 'B2B.Policy.Unit13.Title';
export const B2B_POLICY_UNIT13_PARAGRAPH1 = 'B2B.Policy.Unit13.Paragraph1';
export const B2B_POLICY_UNIT14_TITLE = 'B2B.Policy.Unit14.Title';
export const B2B_POLICY_UNIT14_PARAGRAPH1 = 'B2B.Policy.Unit14.Paragraph1';
export const B2B_POLICY_UNIT15_TITLE = 'B2B.Policy.Unit15.Title';
export const B2B_POLICY_UNIT15_PARAGRAPH1 = 'B2B.Policy.Unit15.Paragraph1';
export const B2B_POLICY_UNIT16_TITLE = 'B2B.Policy.Unit16.Title';
export const B2B_POLICY_UNIT16_PARAGRAPH1 = 'B2B.Policy.Unit16.Paragraph1';
export const B2B_POLICY_TABLE1_COLUMN1_TITLE = 'B2B.Policy.Table1.Column1.Title';
export const B2B_POLICY_TABLE1_COLUMN2_TITLE = 'B2B.Policy.Table1.Column2.Title';
export const B2B_POLICY_TABLE1_COLUMN1_ROW1 = 'B2B.Policy.Table1.Column1.Row1';
export const B2B_POLICY_TABLE1_COLUMN1_ROW2 = 'B2B.Policy.Table1.Column1.Row2';
export const B2B_POLICY_TABLE1_COLUMN1_ROW3 = 'B2B.Policy.Table1.Column1.Row3';
export const B2B_POLICY_TABLE1_COLUMN1_ROW4 = 'B2B.Policy.Table1.Column1.Row4';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT1 = 'B2B.Policy.Table1.GeneralContent1';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT2 = 'B2B.Policy.Table1.GeneralContent2';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT3 = 'B2B.Policy.Table1.GeneralContent3';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT4 = 'B2B.Policy.Table1.GeneralContent4';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT5 = 'B2B.Policy.Table1.GeneralContent5';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT6 = 'B2B.Policy.Table1.GeneralContent6';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT7 = 'B2B.Policy.Table1.GeneralContent7';
export const B2B_POLICY_TABLE1_GENERAL_CONTENT8 = 'B2B.Policy.Table1.GeneralContent8';
