import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';
import OnlinePrivacyNotice from './OnlinePrivacyNotice';
import CaliforniaPrivacyNotice from './CaliforniaPrivacyNotice';
import RepresentativeOfBusiness from './RepresentativeOfBusiness';
import { Brands, Countries } from '../../enums';
import { BrandType, CountryType } from '../../types';
import PersonnelPrivacyNotice from './PersonnelPrivacyNotice';

const PrivacyNoticeUSA: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  const [isPrivacySelected, setIsPrivacySelected] = useState(false);
  const [isB2BSelected, setIsB2BSelected] = useState(false);
  const [isPersonnelSelected, setIsPersonnelSelected] = useState(false);

  return (
    <div className='tandc-content__container terms-condition' id='privacy'>
      <div>
        <h2 className='option-title tandc-title-header'>{i18n.t(rk.PRIVACYNOTICE_TITLE)}</h2>

        <div className='privacy-accordion c-accordion'>
          <div
            data-testid='privacy-title-main'
            className={`${isPrivacySelected ? 'privacy-title-selected' : 'privacy-title'}`}
            role='button'
            tabIndex={0}
            onClick={() => setIsPrivacySelected(!isPrivacySelected)}
            onKeyDown={() => setIsPrivacySelected(!isPrivacySelected)}
          >
            <h3 className='privacy-title-main'>{i18n.t(rk.ONLINE_PRIVACY_NOTICE_TITLE)}</h3>

            <span
              className={`${isPrivacySelected ? 'privacy-icon-selected' : 'privacy-icon'} c-accordion__title-icon`}
            />
          </div>
        </div>
        {isPrivacySelected && (
          <>
            <h4 className='option-title tandc-title-subheader u-mt-xlarge u-mb-none'>
              {i18n.t(rk.PRIVACYNOTICE_TITLE_ONLINE_PRIVACY_NOTICE_HEADER_CA)}
            </h4>
            <div className='privacy-content'>
              <OnlinePrivacyNotice brand={brand} />
            </div>

            <h4 className='option-title tandc-title-subheader u-mt-xlarge u-mb-none'>
              {i18n.t(rk.PRIVACYNOTICE_TITLE_PRIVACY_ACT_RIGHTS)}
            </h4>
            <div className='privacy-content'>
              <CaliforniaPrivacyNotice brand={brand} />
            </div>
          </>
        )}

        <div className='privacy-accordion c-accordion'>
          <div
            data-testid='privacy-title-B2B'
            className={`${isB2BSelected ? 'privacy-title-selected' : 'privacy-title'}`}
            role='button'
            tabIndex={0}
            onClick={() => setIsB2BSelected(!isB2BSelected)}
            onKeyDown={() => setIsB2BSelected(!isB2BSelected)}
          >
            <h3 className='privacy-title-main'>Representative of a Business</h3>

            <span className={`${isB2BSelected ? 'privacy-icon-selected' : 'privacy-icon'} c-accordion__title-icon`} />
          </div>
        </div>

        {isB2BSelected && (
          <div className='privacy-content'>
            <RepresentativeOfBusiness />
          </div>
        )}

        <div className='privacy-accordion c-accordion'>
          <div
            data-testid='privacy-title-personnel'
            className={`${isPersonnelSelected ? 'privacy-title-selected' : 'privacy-title'}`}
            role='button'
            tabIndex={0}
            onClick={() => setIsPersonnelSelected(!isPersonnelSelected)}
            onKeyDown={() => setIsPersonnelSelected(!isPersonnelSelected)}
          >
            <h3 className='privacy-title-main'>{i18n.t(rk.PERSONNEL_PRIVACY_NOTICE_TITLE)}</h3>

            <span
              className={`${isPersonnelSelected ? 'privacy-icon-selected' : 'privacy-icon'} c-accordion__title-icon`}
            />
          </div>
        </div>

        {isPersonnelSelected && (
          <div className='privacy-content'>
            <PersonnelPrivacyNotice />
          </div>
        )}

        <br />
        <br />
      </div>
    </div>
  );
};

PrivacyNoticeUSA.propTypes = {
  brand: PropTypes.string.isRequired,
};

const PrivacyNoticeCanada: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;

  return (
    <div className='tandc-content__container terms-condition' id='privacy'>
      <div>
        <h2 className='option-title tandc-title-header'>{i18n.t(rk.PRIVACY_POLICY_TITLE)}</h2>
        <div>
          <br />
          <h3 className='notice-paragraph underline-bold'>
            {i18n.t(brand === Brands.AUDI ? rk.PRIVACY_POLICY_TITLE_AUDI : rk.PRIVACY_POLICY_TITLE_VW)}
          </h3>
        </div>
        {/* intro */}
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_INTRO_PARAGRAPH1)}</p>
        <p>
          {i18n.t(rk.PRIVACY_POLICY_INTRO_PARAGRAPH3_1)}
          {i18n.t(
            brand === Brands.AUDI ? rk.PRIVACY_POLICY_INTRO_PARAGRAPH3_AUDI : rk.PRIVACY_POLICY_INTRO_PARAGRAPH3_VW
          )}
          {i18n.t(rk.PRIVACY_POLICY_INTRO_PARAGRAPH3_2)}
        </p>
        {/* unit 1 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_1)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH1)}</p>
        <ul>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_1)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_2)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_3)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_4)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_5)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_6)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_7)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_8)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_9)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_10)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_11)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH2_LIST1_12)}</li>
        </ul>
        <p className='notice-paragraph'>
          {i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH3_1)}
          <strong>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH3_2)}</strong>
          {i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH3_3)}
        </p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT1_PARAGRAPH4)}</p>
        {/* unit 2 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_2)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH1)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_1)}</p>
        <ul>
          <li className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_1)}</li>
          <li className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_2)}</li>
          <li className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_3)}</li>
          <li className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_4)}</li>
          <li className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_5)}</li>
          <li className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH2_LIST1_6)}</li>
        </ul>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT2_PARAGRAPH4)}</p>
        {/* unit 3 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_3)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH1)}</p>
        <ul>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_1)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_2)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_3)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_4)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_5)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_6)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_7)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_8)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_9)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_10)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_11)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_12)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_13)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_14)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT3_PARAGRAPH2_LIST1_15)}</li>
        </ul>
        {/* unit 4 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_4)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH1)}</p>
        <ul>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_1)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_2)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_3)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_4)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_5)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_6)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH2_LIST1_7)}</li>
        </ul>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT4_PARAGRAPH3)}</p>
        {/* unit 5 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_5)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT5_PARAGRAPH1)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT5_PARAGRAPH2)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT5_PARAGRAPH3)}</p>
        {/* unit 5 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_6)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH1)}</p>
        <ul>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH2_LIST1_1)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH2_LIST1_2)}</li>
        </ul>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH3)}</p>
        <ul>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH4_LIST1_1)}</li>
          <li>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH4_LIST1_2)}</li>
        </ul>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT6_PARAGRAPH5)}</p>
        {/* unit 7 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_7)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT7_PARAGRAPH1)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT7_PARAGRAPH2)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT7_PARAGRAPH3)}</p>
        {/* unit 8 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_8)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT8_PARAGRAPH1)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT8_PARAGRAPH2)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT8_PARAGRAPH3)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT8_PARAGRAPH4)}</p>
        {/* unit 9 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_9)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT9_PARAGRAPH1)}</p>
        {/* unit 10 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_10)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH1)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH2)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH3)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH4)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH5)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH6)}</p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT10_PARAGRAPH7)}</p>
        {/* unit 11 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_11)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT11_PARAGRAPH1)}</p>
        {/* unit 12 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_12)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT12_PARAGRAPH1)}</p>
        {/* unit 13 */}
        <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_POLICY_SUBTITLE_13)}</span>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT13_PARAGRAPH1)}</p>
        <p className='notice-paragraph'>
          {i18n.t(rk.PRIVACY_POLICY_UNIT13_PARAGRAPH2_1)}
          <a href={`mailto:${i18n.t(rk.PRIVACY_POLICY_UNIT13_PARAGRAPH2_2)}`}>
            {i18n.t(rk.PRIVACY_POLICY_UNIT13_PARAGRAPH2_2)}
          </a>
        </p>
        <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_POLICY_UNIT13_PARAGRAPH3)}</p>
      </div>
    </div>
  );
};

PrivacyNoticeCanada.propTypes = {
  brand: PropTypes.string.isRequired,
};

const PrivacyNotice: React.FC<{ country: Countries; brand: Brands }> = (props) => {
  const { country, brand } = props;
  return country === Countries.Canada ? <PrivacyNoticeCanada brand={brand} /> : <PrivacyNoticeUSA brand={brand} />;
};

PrivacyNotice.propTypes = {
  country: CountryType.isRequired,
  brand: BrandType.isRequired,
};

export default PrivacyNotice;
