import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Brands, Countries, Domains } from './enums';
import * as routePaths from './RoutePaths';
import TermsAndCondition from './view/pages/terms-and-conditions/TermsAndCondition';
import DocuSignOAuthCallback from './view/pages/docu-sign-integration-callbacks/DocuSignIntegrationOAuthCallback';
import DocuSignSigningCallback from './view/pages/docu-sign-integration-callbacks/DocuSignIntegrationSignedCallback';

// eslint-disable-next-line
const Routes = () => {
  // Changes for short URLs
  const domainName = window.location.host;

  if (Domains.AUDI.includes(domainName)) {
    return (
      <Switch>
        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to={routePaths.SHORT_AUDI} />;
          }}
        />
        <Route exact path={routePaths.SHORT_AUDI}>
          <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='default' />
        </Route>
        <Route exact path={routePaths.SHORT_AUTHORIZATION_AUDI}>
          <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='Authorization' />
        </Route>
        <Route exact path={routePaths.SHORT_PRIVACY_AUDI}>
          <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='privacy' />
        </Route>
        <Route exact path={routePaths.SHORT_ONLINE_ACCESS_AUDI}>
          <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='online-access' />
        </Route>
        <Route exact path={routePaths.SHORT_ACH_AUDI}>
          <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='ach' />
        </Route>
        <Route exact path={routePaths.DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK}>
          <DocuSignOAuthCallback />
        </Route>
        <Route exact path={routePaths.DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK}>
          <DocuSignSigningCallback />
        </Route>
        <Route exact path={routePaths.SHORT_SMS_AUDI}>
          <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='sms' />
        </Route>
      </Switch>
    );
  }

  if (Domains.VW.includes(domainName)) {
    return (
      <Switch>
        <Route
          exact
          path='/'
          render={() => {
            return <Redirect to={routePaths.SHORT_VW} />;
          }}
        />
        <Route exact path={routePaths.SHORT_VW}>
          <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='default' />
        </Route>
        <Route exact path={routePaths.SHORT_AUTHORIZATION_VW}>
          <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='Authorization' />
        </Route>
        <Route exact path={routePaths.SHORT_PRIVACY_VW}>
          <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='privacy' />
        </Route>
        <Route exact path={routePaths.SHORT_ONLINE_ACCESS_VW}>
          <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='online-access' />
        </Route>
        <Route exact path={routePaths.SHORT_ACH_VW}>
          <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='ach' />
        </Route>
        <Route exact path={routePaths.DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK}>
          <DocuSignOAuthCallback />
        </Route>
        <Route exact path={routePaths.DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK}>
          <DocuSignSigningCallback />
        </Route>
        <Route exact path={routePaths.SHORT_SMS_VW}>
          <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='sms' />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={routePaths.AUDI}>
        <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='default' />
      </Route>
      <Route exact path={routePaths.VW}>
        <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='default' />
      </Route>
      <Route exact path={routePaths.AUTHORIZATION_AUDI}>
        <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='Authorization' />
      </Route>
      <Route exact path={routePaths.PRIVACY_AUDI}>
        <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='privacy' />
      </Route>
      <Route exact path={routePaths.ONLINE_ACCESS_AUDI}>
        <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='online-access' />
      </Route>
      <Route exact path={routePaths.ACH_AUDI}>
        <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='ach' />
      </Route>
      <Route exact path={routePaths.SMS_AUDI}>
        <TermsAndCondition country={Countries.USA} brand={Brands.AUDI} page='sms' />
      </Route>
      <Route exact path={routePaths.AUTHORIZATION_VW}>
        <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='Authorization' />
      </Route>
      <Route exact path={routePaths.PRIVACY_VW}>
        <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='privacy' />
      </Route>
      <Route exact path={routePaths.ONLINE_ACCESS_VW}>
        <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='online-access' />
      </Route>
      <Route exact path={routePaths.ACH_VW}>
        <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='ach' />
      </Route>
      <Route exact path={routePaths.SMS_VW}>
        <TermsAndCondition country={Countries.USA} brand={Brands.VW} page='sms' />
      </Route>
      <Route exact path={routePaths.AUDI_CANADA}>
        <TermsAndCondition country={Countries.Canada} brand={Brands.AUDI} page='default' />
      </Route>
      <Route exact path={routePaths.VW_CANADA}>
        <TermsAndCondition country={Countries.Canada} brand={Brands.VW} page='default' />
      </Route>
      <Route exact path={routePaths.PRIVACY_AUDI_CANADA}>
        <TermsAndCondition country={Countries.Canada} brand={Brands.AUDI} page='privacy' />
      </Route>
      <Route exact path={routePaths.ONLINE_ACCESS_AUDI_CANADA}>
        <TermsAndCondition country={Countries.Canada} brand={Brands.AUDI} page='online-access' />
      </Route>
      <Route exact path={routePaths.PRIVACY_VW_CANADA}>
        <TermsAndCondition country={Countries.Canada} brand={Brands.VW} page='privacy' />
      </Route>
      <Route exact path={routePaths.ONLINE_ACCESS_VW_CANADA}>
        <TermsAndCondition country={Countries.Canada} brand={Brands.VW} page='online-access' />
      </Route>
      <Route exact path={routePaths.DOCU_SIGN_INTEGRATION_OAUTH_CALLBACK}>
        <DocuSignOAuthCallback />
      </Route>
      <Route exact path={routePaths.DOCU_SIGN_INTEGRATION_SIGNED_CALLBACK}>
        <DocuSignSigningCallback />
      </Route>
      <Route
        exact
        path='/'
        render={() => {
          return <Redirect to={routePaths.AUDI} />;
        }}
      />
    </Switch>
  );
};

export default Routes;
