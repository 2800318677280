export enum Brands {
  AUDI = 'Audi',
  VW = 'VW',
  VW6 = 'VW6',
  SKODA = 'Skoda',
  DEFAULT = 'Default',
  BLUELABEL = 'BlueLabel',
}

export enum Countries {
  USA = 'USA',
  Canada = 'Canada',
  DEFAULT = 'Default',
}

export const Domains = {
  AUDI: ['privacy.audifs.com', 'cons.privacy.audifs.com', 'dev.privacy.audifs.com', 'int.privacy.audifs.com'],
  VW: ['privacy.vwcredit.com', 'cons.privacy.vwcredit.com', 'dev.privacy.vwcredit.com', 'int.privacy.vwcredit.com'],
};

export enum Languages {
  EN_US = 'en-US',
  EN_CAN = 'en-CA',
  FR_CAN = 'fr-CA',
}
