/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  spinnerType?: 'full-page' | 'small' | 'center' | 'section';
  ariaLabel: boolean;
}

const Spinner = (props: IProps) => {
  const { children, spinnerType = 'fullpage', ariaLabel } = props;
  return (
    <div className={`c-spinner  c-spinner--${spinnerType}`} aria-live='polite' aria-busy={ariaLabel}>
      {children}
      <div className='c-spinner__loader'>
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
        <span className='c-spinner__element' />
      </div>
    </div>
  );
};

export default Spinner;
