import React from 'react';
import * as PropTypes from 'prop-types';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';

const Authorization: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  return (
    <div className='tandc-content__container terms-condition' id='Authorization'>
      {brand === 'Audi' ? (
        // Audi Authorization Agreement
        <div>
          <h2 className='tandc-title-header'>{i18n.t(rk.AUTHORIZATION_AGREEMENT_TITLE)}</h2>
          <p>
            <b>{i18n.t(rk.AUTOMATIC_PAYMENT_PLAN_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.AUTHORIZATION_AGREEMENT_PAYMENT_PLAN)}</p>
          <p>
            <b>{i18n.t(rk.OPTIONAL_ENROLLMENT_AUTOPAY_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.AUTHORIZATION_AGREEMENT_OPTIONAL_ENROLLMENT)}</p>
          <p>
            <b>{i18n.t(rk.ONE_TIME_PAYMENTS_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.AUTHORIZATION_AGREEMENT_ONE_TIME)}</p>
          <p>
            <b>{i18n.t(rk.SECURE_FUNDS_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.AUTHORIZATION_AGREEMENT_SECURE_FUNDS)}</p>
          <p>
            <b>{i18n.t(rk.MONTHLY_PAYMENTS_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.AUTHORIZATION_AGREEMENT_MONTHLY_PAYMENT)}</p>
          <p>
            <b>{i18n.t(rk.CANCELING_AUTHORIZATION_TITLE)}</b>
          </p>
          <p>
            {i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_1)}
            <b>{i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATIN_5DAYS)}</b>
            {i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_2)}
            <b>{i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATIN_5DAYS)}</b>
            {i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_3)}
          </p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO)}</p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT1)}</p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT2)}</p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT3)}</p>
          <p>
            <b>{i18n.t(rk.EFUNDS_TRANSFER_ACT_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.ELECTRONICS_FUNDS_MESSAGE)}</p>
        </div>
      ) : (
        // VW Authorization Agreement
        <div>
          <h2 className='tandc-title-header'>{i18n.t(rk.AUTHORIZATION_AGREEMENT_TITLE)}</h2>
          <p>
            <b>{i18n.t(rk.AUTOMATIC_PAYMENT_PLAN_TITLE_2)}</b>
          </p>
          <p>{i18n.t(rk.VW_AUTHORIZATION_AGREEMENT_PAYMENT_PLAN)}</p>
          <p>
            <b>{i18n.t(rk.OPTIONAL_ENROLLMENT_AUTOPAY_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.VW_AUTHORIZATION_AGREEMENT_OPTIONAL_ENROLLMENT)}</p>
          <p>
            <b>{i18n.t(rk.ONE_TIME_PAYMENTS_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.VW_AUTHORIZATION_AGREEMENT_ONE_TIME)}</p>
          <p>
            <b>{i18n.t(rk.SECURE_FUNDS_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.AUTHORIZATION_AGREEMENT_SECURE_FUNDS)}</p>
          <p>
            <b>{i18n.t(rk.MONTHLY_PAYMENTS_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.VW_AUTHORIZATION_AGREEMENT_MONTHLY_PAYMENT)}</p>
          <p>
            <b>{i18n.t(rk.CANCELING_AUTHORIZATION_TITLE)}</b>
          </p>
          <p>
            {i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_1)}
            <b>{i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATIN_5DAYS)}</b>
            {i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_2)}
            <b>{i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATIN_5DAYS)}</b>
            {i18n.t(rk.AUTHORIZATION_AGREEMENT_CANCELLING_AUTHORIZATION_3)}
          </p>
          <p>{i18n.t(rk.VW_CANCELLING_AUTHORIZATION_CONTACT_INFO)}</p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT1)}</p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT2)}</p>
          <p>{i18n.t(rk.CANCELLING_AUTHORIZATION_CONTACT_INFO_TEXT3)}</p>
          <p>
            <b>{i18n.t(rk.EFUNDS_TRANSFER_ACT_TITLE)}</b>
          </p>
          <p>{i18n.t(rk.ELECTRONICS_FUNDS_MESSAGE)}</p>
        </div>
      )}
    </div>
  );
};
Authorization.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default Authorization;
