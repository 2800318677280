import React from 'react';
import * as PropTypes from 'prop-types';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';
import audi_californiastate_pdf from '../../assets/pdfs/AFS_CalFIPA_NoticeRev_2024.pdf';
import audi_anyotherstate_pdf from '../../assets/pdfs/AFS_GLBA_NoticeRev_2024.pdf';
import VW_californiastate_pdf from '../../assets/pdfs/VWFS_CalFIPA_NoticeRev_2024.pdf';
import VW_anyotherstate_pdf from '../../assets/pdfs/VWFS_GLBA_NoticeRev_2024.pdf';

const OnlinePrivacyNotice: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  return (
    <div>
      <br />
      <b>
        <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_DESCRIPTION)}</p>
      </b>
      <p className='notice-paragraph low-margin'>
        <b>{i18n.t(rk.PRIVACY_NOTICE_EFFECTIVE_DATE)}</b>
      </p>
      <p className='notice-paragraph low-margin'>
        <b>{i18n.t(rk.PRIVACY_NOTICE_LAST_UPDATED)}</b>
      </p>
      {brand === 'Audi' ? (
        <div>
          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_1)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH1)}</p>
          <p>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH2)}</p>
          <p>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH3)}</p>
          <ul className='list-wrap'>
            <li className='list-item'> {i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH3_1)} </li>
            <li className='list-item'> {i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH3_2)} </li>
          </ul>
          <p>
            <u>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH4)}</u>
          </p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_2)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM6_CONTENT)}
            </li>
          </ul>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_3)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM6_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM7_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM7_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM8_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM8_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM9_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM9_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM10_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM10_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM11_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM11_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_PARAGRAPH2)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_4)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM6_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM7_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM7_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_PARAGRAPH2)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_PARAGRAPH3)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_5)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM6_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM7_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM7_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM8_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM8_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM9_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM9_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM10_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM10_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM11_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM11_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM12_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM12_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM13_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM13_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM14_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM14_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM15_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM15_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM16_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM16_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM17_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM17_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_PARAGRAPH2)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM18_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM18_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM19_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM19_CONTENT)}
            </li>
          </ul>
          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_6)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT6_PARAGRAPH1)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT6_PARAGRAPH2)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT6_PARAGRAPH3)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_7)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT7_PARAGRAPH1)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_8)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT8_PARAGRAPH1)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_9)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT9_PARAGRAPH1)}</p>

          <p className='notice-paragraph'>
            <b>{i18n.t(rk.IF_CALIFORINA_RESIDENT)}</b>
            <a
              href={audi_californiastate_pdf}
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='california state residents privacy notice Audi'
            >
              click here
            </a>
            {i18n.t(rk.VIEW_PRINT_PRIVACY_NOTICE)}
          </p>
          <p className='notice-paragraph'>
            <b>{i18n.t(rk.IF_OTHER_STATE_RESIDENT)}</b>
            <a
              href={audi_anyotherstate_pdf}
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='non-california state residents privacy notice Audi'
            >
              click here
            </a>
            {i18n.t(rk.VIEW_PRINT_PRIVACY_NOTICE)}
          </p>
          <p className='notice-paragraph'>
            {i18n.t(rk.PRIVACY_NOTICE_UNIT9_PARAGRAPH2_1)}
            <a
              href='https://www.audifs.com/Authentication/Login'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='login Audi'
            >
              login
            </a>
            {i18n.t(rk.PRIVACY_NOTICE_UNIT9_PARAGRAPH2_2)}
          </p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_10)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_PARAGRAPH1)}</p>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_PARAGRAPH2)}</p>
          <ul>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM1_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM2_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM3_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM4_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM5_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM6_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM7_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM8_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM9_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM10_CONTENT)}</li>
          </ul>

          <b className='do-not-track-title'>{i18n.t(rk.DO_NOT_TRACK)}</b>
          <p className='do-not-track-p low-margin'>{i18n.t(rk.DO_NOT_TRACK_PARAGRAPH_1)}</p>
          <p className='do-not-track-p low-margin'>{i18n.t(rk.DO_NOT_TRACK_PARAGRAPH_2)}</p>
          <p className='do-not-track-p'>{i18n.t(rk.DO_NOT_TRACK_PARAGRAPH_3)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_11)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT11_PARAGRAPH1)}</p>
          <p className='notice-paragraph'>
            {i18n.t(rk.PRIVACY_NOTICE_UNIT11_PARAGRAPH2)}
            <a
              href='https://www.ftc.gov/'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='ftc website url'
            >
              www.ftc.gov.
            </a>
          </p>
          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_12)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT12_PARAGRAPH1)}</p>
        </div>
      ) : (
        <div>
          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_1)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.VW_PRIVACY_NOTICE_UNIT1_PARAGRAPH1)}</p>
          <p>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH2)}</p>
          <p>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH3)}</p>

          <ul className='list-wrap'>
            <li className='list-item'> {i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH3_1)} </li>
            <li className='list-item'> {i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH3_2)} </li>
          </ul>
          <p>
            <u>{i18n.t(rk.PRIVACY_NOTICE_UNIT1_PARAGRAPH4)}</u>
          </p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_2)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT2_LISTITEM6_CONTENT)}
            </li>
          </ul>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_3)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM6_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM7_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM7_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM8_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM8_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM9_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM9_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM10_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM10_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM11_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT3_LISTITEM11_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT3_PARAGRAPH2)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_4)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM6_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM7_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT4_LISTITEM7_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_PARAGRAPH2)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT4_PARAGRAPH3)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_5)}</span>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_PARAGRAPH1)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM1_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM1_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM2_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM2_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM3_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM3_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM4_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM4_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM5_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM5_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM6_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM6_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM7_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM7_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM8_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM8_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM9_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM9_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM10_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM10_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM11_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM11_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM12_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM12_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM13_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM13_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM14_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM14_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM15_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM15_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM16_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM16_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM17_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM17_CONTENT)}
            </li>
          </ul>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_PARAGRAPH2)}</p>
          <ul className='list-wrap'>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM18_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM18_CONTENT)}
            </li>
            <li className='list-item'>
              <b>{i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM19_TITLE)}</b>
              {i18n.t(rk.PRIVACY_NOTICE_UNIT5_LISTITEM19_CONTENT)}
            </li>
          </ul>
          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_6)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT6_PARAGRAPH1)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT6_PARAGRAPH2)}</p>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT6_PARAGRAPH3)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_7)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT7_PARAGRAPH1)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_8)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT8_PARAGRAPH1)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_9)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT9_PARAGRAPH1)}</p>

          <p className='notice-paragraph'>
            <b>{i18n.t(rk.IF_CALIFORINA_RESIDENT)}</b>
            <a
              href={VW_californiastate_pdf}
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='non-california state residents privacy notice VW'
            >
              click here
            </a>
            {i18n.t(rk.VIEW_PRINT_PRIVACY_NOTICE)}
          </p>
          <p className='notice-paragraph'>
            <b>{i18n.t(rk.IF_OTHER_STATE_RESIDENT)}</b>
            <a
              href={VW_anyotherstate_pdf}
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='non-california state residents privacy notice VW'
            >
              click here
            </a>
            {i18n.t(rk.VIEW_PRINT_PRIVACY_NOTICE)}
          </p>
          <p className='notice-paragraph'>
            {i18n.t(rk.PRIVACY_NOTICE_UNIT9_PARAGRAPH2_1)}
            <a
              href='https://auth.ownerportal.vwcredit.com/login'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='login VW'
            >
              login
            </a>
            {i18n.t(rk.VW_PRIVACY_NOTICE_UNIT9_PARAGRAPH2_2)}
          </p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_10)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_PARAGRAPH1)}</p>
          <p className='notice-paragraph low-margin'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_PARAGRAPH2)}</p>
          <ul>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM1_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM2_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM3_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM4_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM5_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM6_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM7_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM8_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM9_CONTENT)}</li>
            <li className='list-item'>{i18n.t(rk.PRIVACY_NOTICE_UNIT10_LISTITEM10_CONTENT)}</li>
          </ul>

          <b className='do-not-track-title'>{i18n.t(rk.DO_NOT_TRACK)}</b>
          <p className='do-not-track-p low-margin'>{i18n.t(rk.DO_NOT_TRACK_PARAGRAPH_1)}</p>
          <p className='do-not-track-p low-margin'>{i18n.t(rk.DO_NOT_TRACK_PARAGRAPH_2)}</p>
          <p className='do-not-track-p'>{i18n.t(rk.DO_NOT_TRACK_PARAGRAPH_3)}</p>

          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_11)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.PRIVACY_NOTICE_UNIT11_PARAGRAPH1)}</p>
          <p className='notice-paragraph'>
            {i18n.t(rk.PRIVACY_NOTICE_UNIT11_PARAGRAPH2)}
            <a
              href='https://www.ftc.gov/'
              rel='noopener noreferrer'
              target='_blank'
              className=''
              aria-label='ftc website url'
            >
              www.ftc.gov.
            </a>
          </p>
          <span className='privacy-notice-subtitle'>{i18n.t(rk.PRIVACY_NOTICE_SUBTITLE_12)}</span>
          <p className='notice-paragraph'>{i18n.t(rk.VW_PRIVACY_NOTICE_UNIT12_PARAGRAPH1)}</p>
        </div>
      )}
    </div>
  );
};

OnlinePrivacyNotice.propTypes = {
  brand: PropTypes.string.isRequired,
};
export default OnlinePrivacyNotice;
