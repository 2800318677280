import React from 'react';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';

const PersonnelPrivacyNotice = () => {
  return (
    <div className='privacy-content'>
      <br />
      <p className='notice-paragraph low-margin'>
        {i18n.t(rk.PERSONNEL_PRIVACY_NOTICE_P1)}
        <a
          href='http://info.vw.com/vault/hr/new_HR/California_Emp_Personnel_PrivacyNotice/California_Emp_PrivacyNotice.pdf'
          rel='noopener noreferrer'
          target='_blank'
          id='personnel-privacy-notice-link'
          aria-label='Personnel Privacy Notice'
        >
          {i18n.t(rk.PERSONNEL_PRIVACY_NOTICE_LINK)}
        </a>
      </p>
    </div>
  );
};

export default PersonnelPrivacyNotice;
