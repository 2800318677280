import React from 'react';
import * as PropTypes from 'prop-types';
import i18n from '../../localization/i18n';
import * as rk from '../../localization/resourceKeys';
import { PRIVACY_AUDI, PRIVACY_VW } from '../../RoutePaths';

const SMSTermsOfUse: React.FC<{ brand: string }> = (props) => {
  const { brand } = props;
  return (
    <div className='tandc-content__container terms-condition' id='sms'>
      {brand === 'Audi' ? (
        // Audi SMS Terms Of Use
        <div>
          <h2 className='tandc-title-header'>{i18n.t(rk.SMS_TERMSOFSERVICE_TITLE)}</h2>
          <p>
            <b>{i18n.t(rk.SMS_TERMSOFSERVICE_SUBTITLE)}</b>
            <br />
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_HEADER_AFS)}
          </p>
          {/* Paragraph 1 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P1)}
            <a href={i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P2_AFS_LINK)}>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P2)}</a>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P3)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P4)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P5)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_AFS)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P6)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P7_AFS)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P8)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P9)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P10)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P11)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P12)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P13)}
          </p>
          {/* Paragraph 2 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P1)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P2)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P3)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_AFS)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P4)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P5)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P6)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_AFS)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P7)}
          </p>
          {/* Paragraph 3 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P1)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P2)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P3)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_AFS)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P4)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P5)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P6)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_AFS)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P7)}
          </p>
          {/* Paragraph 4 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT4_P1)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT4_P2)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT4_P3)}
          </p>
          {/* Paragraph 5 */}
          <p>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT5_P1)}</p>
          {/* Paragraph 6 */}
          <p>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P1)}</p>
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P2)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P2_AFS)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P3)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P3_AFS)}
          </p>
          {/* Paragraph 7 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT7_P1)}
            <a href={PRIVACY_AUDI}>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT7_P2)}</a>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT7_P3)}
          </p>
          <p>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT8_P1)}</p>
        </div>
      ) : (
        // VW SMS Terms Of Use
        <div>
          <h2 className='tandc-title-header'>{i18n.t(rk.SMS_TERMSOFSERVICE_TITLE)}</h2>
          <p>
            <b>{i18n.t(rk.SMS_TERMSOFSERVICE_SUBTITLE)}</b>
            <br />
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_HEADER_VWC)}
          </p>
          {/* Paragraph 1 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P1)}
            <a href={i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P2_VWC_LINK)}>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P2)}</a>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P3)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P4)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P5)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_VWC)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P6)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P7_VWC)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P8)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P9)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P10)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P11)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P12)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT1_P13)}
          </p>
          {/* Paragraph 2 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P1)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P2)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P3)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_VWC)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P4)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P5)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P6)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_VWC)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT2_P7)}
          </p>
          {/* Paragraph 3 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P1)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P2)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P3)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_VWC)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P4)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P5)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P6)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_SHORTCODE_CODE_VWC)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT3_P7)}
          </p>
          {/* Paragraph 4 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT4_P1)}
            <strong>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT4_P2)}</strong>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT4_P3)}
          </p>
          {/* Paragraph 5 */}
          <p>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT5_P1)}</p>
          {/* Paragraph 6 */}
          <p>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P1)}</p>
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P2)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P2_VWC)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P3)}
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT6_P3_VWC)}
          </p>
          {/* Paragraph 7 */}
          <p>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT7_P1)}
            <a href={PRIVACY_VW}>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT7_P2)}</a>
            {i18n.t(rk.SMS_TERMSOFSERVICE_UNIT7_P3)}
          </p>
          <p>{i18n.t(rk.SMS_TERMSOFSERVICE_UNIT8_P1)}</p>
        </div>
      )}
    </div>
  );
};
SMSTermsOfUse.propTypes = {
  brand: PropTypes.string.isRequired,
};

export default SMSTermsOfUse;
