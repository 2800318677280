import React, { FC, useEffect } from 'react';

const DocuSignIntegrationOAuthCallback: FC = () => {
  useEffect(() => {
    const msg = { source: 'oauthResponse', hash: window.location.hash || '' };
    if (window.opener) {
      window.opener.postMessage(msg, '*');
    } else {
      window.parent.postMessage(msg, '*');
    }
  }, []);
  return (
    <>
      <h2>Please close this tab or window.</h2>
    </>
  );
};

export default DocuSignIntegrationOAuthCallback;
