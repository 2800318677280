import React from 'react';
import './Logo.scss';
import { Brands, Countries, Languages } from '../../../enums';
import { BrandType, LanguageType } from '../../../types';
import logoAudiUSA from '../../../assets/Images/AUFS_Logo_BLACK_RGB_2row.svg';
import logoVWFSUSA from '../../../assets/Images/VW_FS_Logo_DARKBLUE_RGB_2row.svg';
import logoAudiCanadaEn from '../../../assets/Images/AUFS_Logo_EN.png';
import logoAudiCanadaFr from '../../../assets/Images/AUFS_Logo_FR.png';
import logoVWFSCanadaEn from '../../../assets/Images/VWFS_Logo_Canada_En.svg';
import logoVWFSCanadaFr from '../../../assets/Images/VWFS_Logo_Canada_Fr.svg';

const Logo: React.FC<{ country: Countries; brand: Brands; language: Languages }> = (props) => {
  const { country, brand, language } = props;

  let logoCanada = '';
  if (country === Countries.Canada) {
    if (brand === Brands.AUDI) {
      logoCanada = language === Languages.FR_CAN ? logoAudiCanadaFr : logoAudiCanadaEn;
    } else {
      logoCanada = language === Languages.FR_CAN ? logoVWFSCanadaFr : logoVWFSCanadaEn;
    }
  }
  const brandClassName = brand === Brands.AUDI ? 'audi' : 'vw';

  return (
    <div className={`logo-app ${brandClassName}`}>
      {country === Countries.USA && brand === Brands.AUDI && (
        <div className='logo-container'>
          <img src={logoAudiUSA} alt='' className='c-logo__image' />
        </div>
      )}
      {country === Countries.USA && brand === Brands.VW && (
        <div className='logo-container'>
          <img src={logoVWFSUSA} alt='' className='c-logo__image' />
        </div>
      )}
      {country === Countries.Canada && (
        <div className='logo-container-finance'>
          <img src={logoCanada} alt='' className='c-logo__image' />
        </div>
      )}
    </div>
  );
};

Logo.propTypes = {
  brand: BrandType.isRequired,
  language: LanguageType.isRequired,
};

export default Logo;
